<div [ngClass]="{'tab-vertical': orientation === 'vertical'}">
  @if(orientation === 'vertical'){
    <div class="tab-mobile-select" (click)="showMobileMenu = !showMobileMenu">
      @if(activeTabTitle){
        @if(activeTabTitle.isTemplate){
          <ng-template [ngTemplateOutlet]="activeTabTitle.content"></ng-template>
        }@else{
          {{activeTabTitle.content}}
        }
      }
      <div class="select-tab-icon">
        <i class="fa-solid fa-chevron-down"></i>
      </div>
    </div>
  }
  
  <ul
    ngbNav
    #nav="ngbNav"
    [class]="'nav-tabs ' + justifyClass"
    [(activeId)]="activeId"
    [destroyOnHide]="destroyOnHide"
    [orientation]="orientation"
    (navChange)="select($event.nextId)"
    [animation]="false"
    [class.tab-mobile-menu]="showMobileMenu"
  >
    <li *ngFor="let tab of tabs" [ngbNavItem]="tab.id" [disabled]="tab.disabled">
      <button ngbNavLink type="button" class="btn btn-link">
        <i *ngIf="tab.icon" class="{{ tab.icon }} mr-2"></i>
        <span>{{ tab.title }}</span>
        <ng-template [ngTemplateOutlet]="tab.titleTpl?.templateRef || null"></ng-template>
      </button>
      <ng-template ngbNavContent>
        <ng-template [ngTemplateOutlet]="tab.contentTpl?.templateRef || null"></ng-template>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
