<ng-container
  [ngTemplateOutlet]="
    isOnlyDisplayFlow || isAnonymous || isViewOnly || isCalculatePrice ? detailsButton : isUser && !activeInB2B ? priceRequest : buyButton
  "
></ng-container>

<ng-template #detailsButton>
  <div class="input-group">
    <a class="btn btn-block btn-sm btn-primary" [disabled]="disabled" tabindex="-1" [routerLink]="[goToDetailUrl()]" translate>
      {{ !isCalculatePrice ? 'DETAILS' : 'CALCULATE_PRICE'}}
    </a>
  </div>
</ng-template>

<ng-template #priceRequest>
  <a href="mailto:{{ requestPriceEmail }}?subject=Forespørsel {{ id }}" target="_blank" class="request-price-email">
    <button
      type="button"
      class="btn btn-block btn-sm btn-primary"
      [disabled]="disabled"
      [ngClass]="{ 'not-active-in-b2b': !activeInB2B && (isAdmin || isSales) }"
      translate
    >
      ASK_PRICE
    </button>
  </a>
</ng-template>

<ng-template #buyButton>
  <div class="input-group" *ngIf="!showAddBtn; else btn" ngbTooltip="{{ unit }}">
    <div class="input-group-prepend">
      <button
        class="btn btn-primary btn-sm"
        tabindex="-1"
        [disabled]="disabled"
        (click)="sub(); $event.preventDefault(); $event.stopPropagation()"
      >
        <i class="fas fa-minus" style="font-size: 1rem"></i>
      </button>
    </div>
    <!-- <input type="number" class="form-control form-control-sm text-center" [(ngModel)]="currentQty"
      (ngModelChange)="set($event)" (click)="$event.preventDefault(); $event.stopPropagation();"
      [ngModelOptions]="{updateOn: 'blur'}" [step]="stepAmount"> -->
    <input
      #inputEle
      type="number"
      class="form-control form-control-sm text-center p-1"
      [(ngModel)]="currentQty"
      (blur)="set($event.target.value, inputEle)"
      (click)="$event.preventDefault(); $event.stopPropagation()"
      [disabled]="disabled"
      [class.font-weight-bold]="checkModulus(currentQty, originalSize).multiple"
      [step]="stepAmount"
    />
    <!-- <div *ngIf="unit" class="unit">{{unit}}</div> -->
    <div class="input-group-append">
      <button
        class="btn btn-sm btn-primary"
        tabindex="-1"
        [disabled]="disabled"
        (click)="add(); $event.preventDefault(); $event.stopPropagation()"
      >
        <i class="fas fa-plus" style="font-size: 1rem"></i>
      </button>
    </div>
  </div>

  <ng-template #btn>
    <div class="input-group">
      <!-- ORDER was not a good translate tag here because it has other usages-->
      <button
        class="btn btn-block btn-sm btn-primary"
        tabindex="-1"
        [disabled]="disabled"
        [ngClass]="{ 'not-active-in-b2b': !activeInB2B && (isAdmin || isSales) }"
        (click)="add(); $event.preventDefault(); $event.stopPropagation()"
        translate
      >
        ADD_TO_CART
      </button>
    </div>
  </ng-template>

  <div class="alert alert-error" *ngIf="limitOrderErrorMessage" style="z-index: 1">
    {{ 'LIMIT_ORDER_ERROR' | translate: { stock: availability.currentAvailability } }}
  </div>
</ng-template>
