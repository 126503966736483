import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { Product, ProductExtended } from '../../models';
import { Availability } from '../../models/availability';
import { CustomerProductPrice } from '../../models/price';
import { AuthService } from '../../services/auth/auth.service';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { LocationConfigService } from '../../services/location-config/location-config.service';
import { PriceService } from '../../services/price/price.service';
import { GridViewProductCardExtended } from '../concept-detail-product-list-view/concept-detail-product-list-view.component';
import { ProductGridViewComponent } from '../product-grid-view/product-grid-view.component';
import { GungAnonymousConfigService } from '../../services/gung-anonymous-config/gung-anonymous-config.service';

@Component({
  selector: 'lib-product-tree-list-view',
  templateUrl: './product-tree-list-view.component.html',
  styleUrls: ['./product-tree-list-view.component.css']
})
export class ProductTreeListViewComponent extends ProductGridViewComponent {
  mappedData: GridViewProductCardExtended[];
  adjustedScroll = false;

  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected locationConfigService: LocationConfigService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected gungAnonymousConfigService: GungAnonymousConfigService
  ) {
    super(priceService, gungFlowService, authService, gungAnonymousConfigService);
  }

  protected mapItem(
    id: string,
    product: ProductExtended,
    price: CustomerProductPrice,
    availability: Availability
  ): GridViewProductCardExtended {
    // Product package size
    if (
      product.extra.ar &&
      product.extra.ar.q_jis_fast_pakke_strl &&
      product.extra.ar.artfsgforp &&
      Number(product.extra.ar.artfsgforp) > 1
    ) {
      product.packageSize = Number(product.extra.ar.artfsgforp);
    }
    // Remove dynamic values not visible in list
    const dynamicCollumnsValues = [];
    if (this.dynamicColumns) {
      for (const [index, column] of this.dynamicColumns.entries()) {
        if (column.isDisplayGrid) {
          dynamicCollumnsValues.push(product.dynamicCollumnsValues?.[index]);
        }
      }
    }
    product = {
      ...product,
      dynamicCollumnsValues
    };
    return {
      product,
      price,
      availability
    };
  }

  goToDetail(product: Product) {
    this.router.navigate([product.id], { relativeTo: this.route });
  }
}
