import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

export interface GungCustomerAddress{
  id: string;
  customerId: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  zipCode: string;
  countryCode: string;
  phoneNumber: string;
  email: string;
  contactPersonName: string;
  contactPersonEmail: string;
  contactPersonPhone: string;
}

@Injectable({
  providedIn: 'root'
})
export class GungCustomerAddressService {

  private addressesSubjects: { [customerId: string]: BehaviorSubject<GungCustomerAddress[]> } = {};

  constructor(protected http: HttpClient) {}

  addCustomerDeliveryAddresses(
    customerId: string,
    address: GungCustomerAddress
  ): Observable<GungCustomerAddress[]> {
    const url = `json/gung-addresses`;
    return this.http.post<GungCustomerAddress[]>(url, address).pipe(
      tap(
        // Log the result or error
        data => this.updateCustomerAddresses(customerId),
        error => console.log('ERROR', error)
      )
    );
  }

  updateCustomerAddresses(customerId: string): void {
    if (!this.addressesSubjects[customerId]) {
      this.addressesSubjects[customerId] = new BehaviorSubject<GungCustomerAddress[]>([]);
    }
    this.internalGetGungAddresses(customerId).subscribe((cc: GungCustomerAddress[]) =>
      this.addressesSubjects[customerId].next(cc)
    );
  }

  protected internalGetGungAddresses(customerId: string): Observable<GungCustomerAddress[]> {
    const url = `json/gung-addresses/by-customer/${customerId}`;
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<GungCustomerAddress[]>(url, { headers });
  }

  getCustomerGungAddressesFromSubject(customerId: string): Observable<GungCustomerAddress[]> {
    return this.getCustomerGungAddresses(customerId);
  }

  getCustomerGungAddresses(customerId: string): Observable<GungCustomerAddress[]> {
    if (!this.addressesSubjects[customerId]) {
      this.addressesSubjects[customerId] = new BehaviorSubject<GungCustomerAddress[]>([]);
      this.updateCustomerAddresses(customerId);
    }
    return this.addressesSubjects[customerId].asObservable();
  }

  deleteGungAddresses(customerId, locationId): Observable<GungCustomerAddress> {
    const url = `/json/gung-addresses/${locationId}`;
    return this.http.delete<GungCustomerAddress>(url).pipe(
      tap(
        // Log the result or error
        data => this.updateCustomerAddresses(customerId),
        error => console.log('ERROR', error)
      )
    );
  }
}
