import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { catchError, first, of, switchMap } from 'rxjs';
import { AssortmentService } from '../../services/assortment.service';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-articles-first-subassortment',
  templateUrl: './articles-first-subassortment.component.html',
  styleUrl: './articles-first-subassortment.component.scss'
})
export class ArticlesFirstSubassortmentComponent implements OnInit {

  constructor(protected authService: AuthService, protected assortmentService: AssortmentService, protected router: Router){

  }

  ngOnInit(): void {
    this.authService.getCurrentUser().pipe(
      first(),
      switchMap(user => 
        this.assortmentService.getAssortment(user.assortment).pipe(
          first(),
          catchError(err => {
            console.error('Error fetching assortment:', err);
            return of(null); 
          })
        )
      )
    ).subscribe(assortment => {
      const firstChildId = assortment?.children?.[0]?.id;
      if (firstChildId) {
        this.router.navigate(['/articles', firstChildId]);
      }
    });
  }

}
