import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeSv from '@angular/common/locales/sv';
import { inject, Inject, LOCALE_ID, NgModule, Optional } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRouteSnapshot, Router, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { filter, first } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GungListModule, FilterListDateUtilService } from 'gung-list';
import { AppWrapperComponent } from './components/app-wrapper/app-wrapper.component';
import { AvailabilityComponent } from './components/availability/availability.component';
import { BuyBtnComponent } from './components/buy-btn/buy-btn.component';
import { ManageCachesComponent } from './components/cache/manage-caches/manage-caches.component';
import { CartItemComponent } from './components/cart-item/cart-item.component';
import { CartListLayoutComponent } from './components/cart-list-layout/cart-list-layout.component';
import { CartSummaryComponent } from './components/cart-summary/cart-summary.component';
import { CartComponent } from './components/cart/cart.component';
import { ButtonsComponent } from './components/checkout/buttons/buttons.component';
import { AssignFlowSettingComponent } from './components/checkout/steps/assign-flow-setting/assign-flow-setting.component';
import { AssignOrderRowsComponent } from './components/checkout/steps/assign-order-rows/assign-order-rows.component';
import { CheckoutStepComponent } from './components/checkout/steps/base/checkout-step.component';
import { CartSalesStepComponent } from './components/checkout/steps/cart-sales-step/cart-sales-step.component';
import { CartTotalStepComponent } from './components/checkout/steps/cart-total-step/cart-total-step.component';
import { EmailRecipientComponent } from './components/checkout/steps/email-recipient/email-recipient.component';
import { InitCheckoutObjectComponent } from './components/checkout/steps/init-checkout-object/init-checkout-object.component';
import { QuantityValidationStepComponent } from './components/checkout/steps/quantity-validation-step/quantity-validation-step.component';
import { CheckoutWrapperComponent } from './components/checkout/wrapper/checkout-wrapper/checkout-wrapper.component';
import { ConfirmationEmailsComponent } from './components/confirmation-emails/confirmation-emails.component';
import { CustomPageDirectiveDirective } from './components/custom-page/custom-page-directive/custom-page-directive.directive';
import { CustomPageDisplayComponent } from './components/custom-page/custom-page-display/custom-page-display.component';
import { CustomPageEditorModalComponent } from './components/custom-page/custom-page-editor-modal/custom-page-editor-modal.component';
import { CustomPageEditorComponent } from './components/custom-page/custom-page-editor/custom-page-editor.component';
import { HomeAssortmentTemplateComponent } from './components/custom-page/default-template/home-assortment-template/home-assortment-template.component';
import { HomeDefaultTemplateComponent } from './components/custom-page/default-template/home-default-template/home-default-template.component';
import { HomeFastSearchTemplateComponent } from './components/custom-page/default-template/home-fast-search-template/home-fast-search-template.component';
import { CustomStepperComponent } from './components/custom-stepper/custom-stepper.component';
import { CustomerContactCardListComponent } from './components/customer-contact-card-list/customer-contact-card-list.component';
import { CustomerContactListComponent } from './components/customer-contact-list/customer-contact-list.component';
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { CustomerListCardComponent } from './components/customer-list-card/customer-list-card.component';
import { CustomerListComponent } from './components/customer-list/customer-list.component';
import { CustomerSelectDropdownComponent } from './components/customer-select-dropdown/customer-select-dropdown.component';
import { DeliveryDatePickerComponent } from './components/delivery-date-picker/delivery-date-picker.component';
import { DiscountInputComponent } from './components/discount-input/discount-input.component';
import { DocumentsUploadModalComponent } from './components/documents-upload/documents-upload-modal/documents-upload-modal.component';
import { DocumentsComponent } from './components/documents-upload/documents/documents.component';
import { FlowSelectComponent } from './components/flow-select/flow-select.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { GungDataExportConfirmModalComponent } from './components/gung-data-export-confirm-modal/gung-data-export-confirm-modal.component';
import { GungDateOutputComponent } from './components/gung-date-output/gung-date-output.component';
import { GungMetaOutputComponent } from './components/gung-meta-output/gung-meta-output.component';
import { HomeComponent } from './components/home/home.component';
import { InvoiceCardListComponent } from './components/invoice-card-list/invoice-card-list.component';
import { InvoiceListComponent } from './components/invoice-list/invoice-list.component';
import { InvoiceTableComponent } from './components/invoice-table/invoice-table.component';
import { LangSelectComponent } from './components/lang-select/lang-select.component';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { FashionLoginComponent } from './components/login/fashion-login/fashion-login.component';
import { LoginWrapperComponent } from './components/login/login-wrapper/login-wrapper.component';
import { LoginComponent } from './components/login/login.component';
import { NavbarLoginComponent } from './components/login/navbar-login/navbar-login.component';
import { MetaSelectInputComponent } from './components/meta-select-input/meta-select-input.component';
import { MyCustomPagesComponent } from './components/my-custom-pages/my-custom-pages.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NavbarcartComponent as NavbarCartComponent } from './components/navbarcart/navbarcart.component';
import { OpenOrderCardListComponent } from './components/open-order-card-list/open-order-card-list.component';
import { OpenOrderTableComponent } from './components/open-order-table/open-order-table.component';
import { OpenOrdersComponent } from './components/open-orders/open-orders.component';
import { OrderCardListComponent } from './components/order-card-list/order-card-list.component';
import { OrderConfirmationComponent } from './components/order-confirmation/order-confirmation.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { OrderListComponent } from './components/order-list/order-list.component';
import { PriceComponent } from './components/price/price.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { ProductExportCardComponent } from './components/product-export-card/product-export-card.component';
import { ProductExportListComponent } from './components/product-export-list/product-export-list.component';
import { ProductExportTableComponent } from './components/product-export-table/product-export-table.component';
import { ProductFastSearchComponent } from './components/product-fast-search/product-fast-search.component';
import { ProductFastsearchCardComponent } from './components/product-fastsearch-card/product-fastsearch-card.component';
import { ProductFastsearchDetailComponent } from './components/product-fastsearch-detail/product-fastsearch-detail.component';
import { ProductGridViewComponent } from './components/product-grid-view/product-grid-view.component';
import { ProductListByIdsComponent } from './components/product-list-by-ids/product-list-by-ids.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductPaginationListComponent } from './components/product-pagination-list/product-pagination-list.component';
import { ProductSelectDropdownComponent } from './components/product-select-dropdown/product-select-dropdown.component';
import { QuotesCardListComponent } from './components/quotes-card-list/quotes-card-list.component';
import { QuotesTableComponent } from './components/quotes-table/quotes-table.component';
import { QuotesComponent } from './components/quotes/quotes.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SaveCartModalComponent } from './components/save-cart-modal/save-cart-modal.component';
import { SavedCartListComponent } from './components/saved-cart-list/saved-cart-list.component';
import { SavedCartTableComponent } from './components/saved-cart-table/saved-cart-table.component';
import { StepIndicatorComponent } from './components/step-indicator/step-indicator.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { CreateUserModalComponent } from './components/users/create-user-modal/create-user-modal.component';
import { DeleteUserModalComponent } from './components/users/delete-user-modal/delete-user-modal.component';
import { ImportUsersComponent } from './components/users/import-users/import-users.component';
import { ManageUsersWrapperComponent } from './components/users/manage-users-wrapper/manage-users-wrapper.component';
import { UserDetailsCustomersTableComponent } from './components/users/user-details/user-details-customers/user-details-customers-table/user-details-customers-table.component';
import { UserDetailsCustomersComponent } from './components/users/user-details/user-details-customers/user-details-customers.component';
import { UserDetailsGroupsComponent } from './components/users/user-details/user-details-groups/user-details-groups.component';
import { UserDetailsMultistocksComponent } from './components/users/user-details/user-details-multistocks/user-details-multistocks.component';
import { UserDetailsRolesComponent } from './components/users/user-details/user-details-roles/user-details-roles.component';
import { UserDetailsUserComponent } from './components/users/user-details/user-details-user/user-details-user.component';
import { UserDetailsComponent } from './components/users/user-details/user-details.component';
import { UserTableComponent } from './components/users/user-table/user-table.component';
import { AdminGuard } from './guards/adminguard/admin-guard.guard';
import { SalesGuard } from './guards/salesguard/sales-guard.guard';
import { ActuatorGuard } from './guards/actuatorguard/actuator-guard.guard';
import { AuthGuard } from './guards/authguard/auth-guard';
import { GungCurrencyPipe } from './pipes/gung-currency.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { CacheInterceptorService } from './services/cache-interceptor/cache-interceptor.service';
import { CustomPageResolverService } from './services/custom-page/custom-page-resolver.service';
import { ProductSelectionService } from './services/product-selection-service/product-selection.service';
import { StateModule } from './state/state.module';
import { GOOGLE_ANALYTICS_ID, GOOGLE_TAG_MANAGER_ID } from './utils/google-analytics.provider';
import { FavouriteProductIconComponent } from './components/favourite-product-icon/favourite-product-icon/favourite-product-icon.component';
import { FavouriteProductListComponent } from './components/favourite-product-list/favourite-product-list.component';
import { GungDataExportPricatConfirmModalComponent } from './components/gung-data-export-pricat-confirm-modal/gung-data-export-pricat-confirm-modal.component';
import { GungFlowsWrapperComponent } from './components/gung-flows/gung-flows-wrapper/gung-flows-wrapper.component';
import { GungFlowDetailsComponent } from './components/gung-flows/gung-flow-details/gung-flow-details.component';
import { CreateGungFlowModalComponent } from './components/gung-flows/create-gung-flow-modal/create-gung-flow-modal.component';
import { GungFlowsTableComponent } from './components/gung-flows/gung-flows-table/gung-flows-table.component';
import { GungFlowDetailsProductSettingsComponent } from './components/gung-flows/gung-flow-details/gung-flow-details-product-settings/gung-flow-details-product-settings.component';
import { GungFlowDetailsOrderSettingsComponent } from './components/gung-flows/gung-flow-details/gung-flow-details-order-settings/gung-flow-details-order-settings.component';
import { GungFlowDetailsBasicSettingsComponent } from './components/gung-flows/gung-flow-details/gung-flow-details-basic-settings/gung-flow-details-basic-settings.component';
import { ProductDetailMatrixModalComponent } from './components/matrix/product/product-detail-matrix-modal/product-detail-matrix-modal.component';
import { ProductMatrixComponent } from './components/matrix/product/product-matrix/product-matrix.component';
import { ProductMatrixSummaryElementComponent } from './components/matrix/product/product-matrix-summary-element/product-matrix-summary-element.component';
import { ProductMatrixWrapperInputElementComponent } from './components/matrix/product/product-matrix-wrapper-input-element/product-matrix-wrapper-input-element.component';
import { ProductMatrixElementSimplifiedAvailabilityComponent } from './components/matrix/product/product-matrix-element-simplified-availability/product-matrix-element-simplified-availability.component';
import { ProductMatrixInputElementComponent } from './components/matrix/product/product-matrix-input-element/product-matrix-input-element.component';
import { ProductMatrixElementAvailabilityModalComponent } from './components/matrix/product/product-matrix-element-availability-modal/product-matrix-element-availability-modal.component';
import { ExportCoverSheetsComponent } from './components/export-cover-sheets/export-cover-sheets.component';
import { NewCoverSheetModalComponent } from './components/export-cover-sheets/new-cover-sheet-modal/new-cover-sheet-modal.component';
import { OrderChangeCustomerConfirmationModalComponent } from './components/order-change-customer-confirmation-modal/order-change-customer-confirmation-modal.component';
import { ImportLinesheetComponent } from './components/linesheet/import-linesheet/import-linesheet.component';
import { AvailabilityModalComponent } from './components/availability/availability-modal/availability-modal.component';
import { CreateQuotationModalComponent } from './components/create-quotation-modal/create-quotation-modal.component';
import { SavedQuoteListComponent } from './components/saved-quote-list/saved-quote-list.component';
import { SavedQuoteTableComponent } from './components/saved-quote-table/saved-quote-table.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { SideButtonsComponent } from './components/checkout/side-buttons/side-buttons.component';
import { FooterComponent } from './components/footer/footer.component';
import { CartListComponent } from './components/cart-list/cart-list.component';
import { RequestAccountComponent } from './components/request-account/request-account.component';
import { ProductLogoApplicatorListComponent } from './components/product-logo-applicator-list/product-logo-applicator-list.component';
import { ProductLogoApplicatorGridViewComponent } from './components/product-logo-applicator-grid-view/product-logo-applicator-grid-view.component';
import { ProductDetailLogoApplicatorComponent } from './components/product-detail-logo-applicator/product-detail-logo-applicator.component';
import { CartLogoApplicatorModalComponent } from './components/cart-logo-applicator-modal/cart-logo-applicator-modal.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { AssortmentTreeNavigationComponent } from './components/assortment-tree-navigation/assortment-tree-navigation.component';
import { AssortmentTreeListViewComponent } from './components/assortment-tree-list-view/assortment-tree-list-view.component';
import { AssortmentTreeListComponent } from './components/assortment-tree-list/assortment-tree-list.component';
import { ConceptTreeListComponent } from './components/concept-tree-list/concept-tree-list.component';
import { ConceptTreeListViewComponent } from './components/concept-tree-list-view/concept-tree-list-view.component';
import { ProductTreeListViewComponent } from './components/product-tree-list-view/product-tree-list-view.component';
import { ProductTreeListComponent } from './components/product-tree-list/product-tree-list.component';
import { ConceptDetailComponent } from './components/concept-detail/concept-detail.component';
import { ConceptDetailProductListViewComponent } from './components/concept-detail-product-list-view/concept-detail-product-list-view.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { ImageCarouselComponent } from './components/image-carousel/image-carousel.component';
import { HomeAssortmentNewTemplateComponent } from './components/custom-page/default-template/home-assortment-new-template/home-assortment-new-template.component';
import { StripePaymentFailureComponent } from './components/stripe/stripe-payment-failure/stripe-payment-failure.component';
import { StripePaymentSuccessComponent } from './components/stripe/stripe-payment-success/stripe-payment-success.component';
import { RemoveBlockedProductsStepComponent } from './components/checkout/steps/remove-blocked-products-step/remove-blocked-products-step.component';
import { PimWrapperComponent } from './components/pim/pim-wrapper/pim-wrapper.component';
import { PimGridViewComponent } from './components/pim/pim-grid-view/pim-grid-view.component';
import { PimProductComponent } from './components/pim/pim-product/pim-product.component';
import { PimCreateModelModalComponent } from './components/pim/pim-create-model-modal/pim-create-model-modal.component';
import { PimImportExcelModalComponent } from './components/pim/pim-import-excel-modal/pim-import-excel-modal.component';
import { PimUploadModalComponent } from './components/pim/pim-upload-modal/pim-upload-modal.component';
import { PimProductBasicInfoComponent } from './components/pim/pim-product/pim-product-basic-info/pim-product-basic-info.component';
import { PimProductAttributesComponent } from './components/pim/pim-product/pim-product-attributes/pim-product-attributes.component';
import { PimProductImagesComponent } from './components/pim/pim-product/pim-product-images/pim-product-images.component';
import { PimProductDocumentsComponent } from './components/pim/pim-product/pim-product-documents/pim-product-documents.component';
import { PimProductRelatedProductsComponent } from './components/pim/pim-product/pim-product-related-products/pim-product-related-products.component';
import { PimProductVariantsComponent } from './components/pim/pim-product/pim-product-variants/pim-product-variants.component';
import { ProductTreeTableViewComponent } from './components/product-tree-table-view/product-tree-table-view.component';
import { ConceptTreeTableViewComponent } from './components/concept-tree-table-view/concept-tree-table-view.component';
import { PdfToCartComponent } from './components/pdf-to-cart/pdf-to-cart.component';
import { FileDropDirective } from './directives/file-drop.directive';
import { HomeFashionTemplateComponent } from './components/custom-page/default-template/home-fashion-template/home-fashion-template.component';
import { UserDetailsSubassortmentComponent } from './components/users/user-details/user-details-subassortment/user-details-subassortment.component';
import { CanDeactivateGuard } from './guards/candeactivateguard/can-deactivate.guard';
import { PimUploadExcelModalComponent } from './components/pim/pim-upload-excel-modal/pim-upload-excel-modal.component';
import { UserDetailsOrderParamsComponent } from './components/users/user-details/user-details-order-params/user-details-order-params.component';
import { DeliveryLocationComponent } from './components/delivery-location/delivery-location.component';
import { DeliveryLocationListCardComponent } from './components/delivery-location-list-card/delivery-location-list-card.component';
import { EdiWrapperComponent } from './components/edi/edi-wrapper/edi-wrapper.component';
import { EdiGlnNumbersComponent } from './components/edi/edi-gln-numbers/edi-gln-numbers.component';
import { EdiMessagesComponent } from './components/edi/edi-messages/edi-messages.component';
import { EdiGlnNumbersTableViewComponent } from './components/edi/edi-gln-numbers-table-view/edi-gln-numbers-table-view.component';
import { EdiMessagesTableViewComponent } from './components/edi/edi-messages-table-view/edi-messages-table-view.component';
import { EdiGlnNumbersEditModalComponent } from './components/edi/edi-gln-numbers-edit-modal/edi-gln-numbers-edit-modal.component';
import { EdiInvoiceModalComponent } from './components/edi/edi-invoice-modal/edi-invoice-modal.component';
import { EdiDespatchAdviceModalComponent } from './components/edi/edi-despatch-advice-modal/edi-despatch-advice-modal.component';
import { ProductInlineSearchComponent } from './components/product-inline-search/product-inline-search.component';
import { ProductInlineSearchCardComponent } from './components/product-inline-search-card/product-inline-search-card.component';
import { AvailabilityConditionsComponent } from './components/availability-conditions/availability-conditions.component';
import { CreateAvailabilityConditionModalComponent } from './components/availability-conditions/create-availability-condition-modal/create-availability-condition-modal.component';
import { PimConceptWrapperComponent } from './components/pim-concept/pim-concept-wrapper/pim-concept-wrapper.component';
import { PimConceptGridViewComponent } from './components/pim-concept/pim-concept-grid-view/pim-concept-grid-view.component';
import { EdiImportXmlComponent } from './components/edi/edi-import-xml/edi-import-xml.component';
import { OrderSubmitErrorComponent } from './components/checkout/order-submit-error/order-submit-error.component';
import { DigitalAssetsGridViewComponent } from './components/digital-assets/digital-assets-grid-view/digital-assets-grid-view.component';
import { DigitalAssetsManagementItemComponent } from './components/digital-assets/digital-assets-management-item/digital-assets-management-item.component';
import { DigitalAssetsWrapperComponent } from './components/digital-assets/digital-assets-wrapper/digital-assets-wrapper.component';
import { DigitalAssetsFolderGridViewComponent } from './components/digital-assets/digital-assets-folder-grid-view/digital-assets-folder-grid-view.component';
import { AuthService } from './services/auth/auth.service';
import { NewsActionConfigService } from './services/news/news-action-config/news-action-config.service';
import { NewsPageTableComponent } from './components/news/news-page-table/news-page-table.component';
import { NewsPageListComponent } from './components/news/news-page-list/news-page-list.component';
import { NewsEditorModalComponent } from './components/news/news-editor-modal/news-editor-modal.component';
import { NewsEditorListComponent } from './components/news/news-editor-list/news-editor-list.component';
import { NewsEditorTableComponent } from './components/news/news-editor-table/news-editor-table.component';
import { NewsModalComponent } from './components/news/news-modal/news-modal.component';
import { GungFlowDetailsHtmlComponent } from './components/gung-flows/gung-flow-details/gung-flow-details-html/gung-flow-details-html.component';
import { AccountRequestsManageComponent } from './components/accoun-request/account-requests-manage/account-requests-manage.component';
import { AccountRequestsCardListComponent } from './components/accoun-request/account-requests-card-list/account-requests-card-list.component';
import { PimMetadataCardListComponent } from './components/pim-metadata/pim-metadata-card-list/pim-metadata-card-list.component';
import { PimMetadataWrapperComponent } from './components/pim-metadata/pim-metadata-wrapper/pim-metadata-wrapper.component';
import { PimMetadataModalComponent } from './components/pim-metadata/pim-metadata-modal/pim-metadata-modal.component';
import { PimDimensionsModalComponent } from './components/pim-dimensions/pim-dimensions-modal/pim-dimensions-modal.component';
import { PimDimensionsWrapperComponent } from './components/pim-dimensions/pim-dimensions-wrapper/pim-dimensions-wrapper.component';
import { PimDimensionsCardListComponent } from './components/pim-dimensions/pim-dimensions-card-list/pim-dimensions-card-list.component';
import { BrandsManagerComponent } from './components/brands/brands-manager/brands-manager.component';
import { BrandEditComponent } from './components/brands/brand-edit/brand-edit.component';
import { BrandsListComponent } from './components/brands/brands-list/brands-list.component';
import { MetaDataSelectInputComponent } from './components/meta-data-select-input/meta-data-select-input.component';
import { MetaDataCheckboxInputComponent } from './components/meta-data-checkbox-input/meta-data-checkbox-input.component';
import { CustomerOrderFinderComponent } from './components/customer-order-finder/customer-order-finder.component';
import { OrderFinderCardListComponent } from './components/order-finder-card-list/order-finder-card-list.component';
import { OrderFinderListLayoutComponent } from './components/order-finder-list-layout/order-finder-list-layout.component';
import { CustomerOrderAnalyticsComponent } from './components/customer-order-analytics/customer-order-analytics.component';
import { OrderAnalyticsListComponent } from './components/order-analytics/order-analytics-list/order-analytics-list.component';
import { OrderAnalyticsChartTableComponent } from './components/order-analytics/order-analytics-chart-table/order-analytics-chart-table.component';
import { NgChartsModule } from 'ng2-charts';
import { JetshopManagementPimComponent } from './components/jetshop/jetshop-management-pim/jetshop-management-pim.component';
import { NewsPageGridViewComponent } from './components/news/news-page-grid-view/news-page-grid-view.component';
import { NewsPageSingleComponent } from './components/news/news-page-single/news-page-single.component';
import { ActuatorWrapperComponent } from './components/actuator/actuator-wrapper/actuator-wrapper.component';
import { ActuatorInfoComponent } from './components/actuator/actuator-info/actuator-info.component';
import { ConfigRestartComponent } from './components/actuator/config-restart/config-restart.component';
import { OrderContextComponent } from './components/actuator/order-context/order-context.component';
import { MultilangDatepickerService } from './services/multilang-datepicker/multilang-datepicker.service';
import { ActivityInternalListComponent } from './components/activity-internal/activity-internal-list/activity-internal-list.component';
import { ActivityInternalTableComponent } from './components/activity-internal/activity-internal-table/activity-internal-table.component';
import { ActivityInternalViewModalComponent } from './components/activity-internal/activity-internal-view-modal/activity-internal-view-modal.component';
import { ActivityInternalWrapperComponent } from './components/activity-internal/activity-internal-wrapper/activity-internal-wrapper.component';
import { ActivityInternalCreateModalComponent } from './components/activity-internal/activity-internal-create-modal/activity-internal-create-modal.component';
import { CustomerUserListComponent } from './components/customer-user-list/customer-user-list.component';
import { CustomerUserCardListComponent } from './components/customer-user-card-list/customer-user-card-list.component';
import { UpdateUserModalComponent } from './components/users/update-user-modal/update-user-modal.component';
import { PimProductShopifyVariantsComponent } from './components/pim/pim-product/pim-product-shopify/pim-product-shopify-variants/pim-product-shopify-variants.component';
import { PimProductShopifyComponent } from './components/pim/pim-product/pim-product-shopify/pim-product-shopify.component';
import { PimProductShopifyAttributesComponent } from './components/pim/pim-product/pim-product-shopify/pim-product-shopify-attributes/pim-product-shopify-attributes.component';
import { AssortmentManagementComponent } from './components/assortment-management/assortment-management.component';
import { AssortmentManagementImportComponent } from './components/assortment-management/assortment-management-import/assortment-management-import.component';
import { AssortmentManagementExportComponent } from './components/assortment-management/assortment-management-export/assortment-management-export.component';
import { DiscountCodesComponent } from './components/discount-codes/discount-codes.component';
import { DiscountCodesTableComponent } from './components/discount-codes/discount-codes-table/discount-codes-table.component';
import { DiscountCodesModalComponent } from './components/discount-codes/discount-codes-modal/discount-codes-modal.component';
import { ShippingChargesManagerComponent } from './components/shipping-charges-manager/shipping-charges-manager.component';
import { AssignDiscountCodeComponent } from './components/checkout/steps/assign-discount-code/assign-discount-code.component';
import { QuickorderComponent } from './components/quickorder/quickorder.component';
import { QuickorderProductDetailsComponent } from './components/quickorder/quickorder-product-details/quickorder-product-details.component';
import { QuickorderSummarizeComponent } from './components/quickorder/quickorder-summarize/quickorder-summarize.component';
import { QuickorderListingProductComponent } from './components/quickorder/quickorder-listing-product/quickorder-listing-product.component';
import { SendCountingTransactionComponent } from './components/send-counting-transaction/send-counting-transaction.component';
import { EdiListOrdersComponent } from './components/edi/edi-list-orders/edi-list-orders.component';
import { EdiOrderCardListComponent } from './components/edi/edi-order-card-list/edi-order-card-list.component';
import { EdiOrderDetailComponent } from './components/edi/edi-order-detail/edi-order-detail.component';
import { ProductsBookmarkCreateModalComponent } from './components/products-bookmark/products-bookmark-create-modal/products-bookmark-create-modal.component';
import { ProductsBookmarkManagementComponent } from './components/products-bookmark/products-bookmark-management/products-bookmark-management.component';
import { ProductsBookmarkManagementModalComponent } from './components/products-bookmark/products-bookmark-management-modal/products-bookmark-management-modal.component';
import { MongoAssortmentManagementModalComponent } from './components/mongo-assortment-management-modal/mongo-assortment-management-modal.component';
import { MongoAssortmentManagementComponent } from './components/mongo-assortment-management/mongo-assortment-management.component';
import { MongoAssortmentCreateModalComponent } from './components/mongo-assortment-create-modal/mongo-assortment-create-modal.component';
import { EdiInvoicesComponent } from './components/edi/edi-invoices/edi-invoices.component';
import { EdiInvoiceCardListComponent } from './components/edi/edi-invoice-card-list/edi-invoice-card-list.component';
import { EdiJointOrderSummaryComponent } from './components/edi/edi-joint-order-summary/edi-joint-order-summary.component';
import { EdiModalReleaseOrdersComponent } from './components/edi/edi-modal/edi-modal-release-orders/edi-modal-release-orders.component';
import { EdiModalChangeDeliveryDateComponent } from './components/edi/edi-modal/edi-modal-change-delivery-date/edi-modal-change-delivery-date.component';
import { EdiModalAddCampaignProductsComponent } from './components/edi/edi-modal/edi-modal-add-campaign-products/edi-modal-add-campaign-products.component';
import { EdiInvoiceDetailComponent } from './components/edi/edi-invoice-detail/edi-invoice-detail.component';
import { EdiDispatchAdvicesComponent } from './components/edi/edi-dispatch-advices/edi-dispatch-advices.component';
import { EdiDispatchCardListComponent } from './components/edi/edi-dispatch-card-list/edi-dispatch-card-list.component';
import { EdiDispatchAdviceDetailComponent } from './components/edi/edi-dispatch-advice-detail/edi-dispatch-advice-detail.component';
import { EdiOrderResponseComponent } from './components/edi/edi-order-response/edi-order-response.component';
import { EdiOrderResponseCardListComponent } from './components/edi/edi-order-response-card-list/edi-order-response-card-list.component';
import { EdiOrderResponseDetailComponent } from './components/edi/edi-order-response-detail/edi-order-response-detail.component';
import { EdiOrderBulkEditComponent } from './components/edi/edi-order-bulk-edit/edi-order-bulk-edit.component';
import { EdiGlnDetailsTableComponent } from './components/edi/edi-gln-details-table/edi-gln-details-table.component';
import { AssignSystemSettingComponent } from './components/checkout/steps/assign-system-setting/assign-system-setting.component';
import { LoginCheckService } from './services/login-check.service';
import { WhiteboardGridComponent } from './whiteboard/components/whiteboard-grid/whiteboard-grid.component';
import { WhiteboardActionsComponent } from './whiteboard/components/whiteboard-actions/whiteboard-actions.component';
import { WhiteboardOptionsComponent } from './whiteboard/components/whiteboard-options/whiteboard-options.component';
import { WhiteboardProductListComponent } from './whiteboard/components/whiteboard-product-list/whiteboard-product-list.component';
import { WhiteboardSaveModalComponent } from './whiteboard/components/whiteboard-save-modal/whiteboard-save-modal.component';
import { WhiteboardProductListViewComponent } from './whiteboard/components/whiteboard-product-list/whiteboard-product-list-view/whiteboard-product-list-view.component';
import { Angulartics2Module } from 'angulartics2';
import { GridsterModule } from 'angular-gridster2';
import { WhiteboardManagementModalComponent } from './whiteboard/components/whiteboard-management-modal/whiteboard-management-modal.component';
import { WhiteboardManagementComponent } from './whiteboard/components/whiteboard-management/whiteboard-management.component';
import { WhiteboardCreateModalComponent } from './whiteboard/components/whiteboard-create-modal/whiteboard-create-modal.component';
import { WhiteboardOrderProductListComponent } from './whiteboard/components/whiteboard-order-product-list/whiteboard-order-product-list.component';
import { DocumentsEditModalComponent } from './components/documents-upload/documents-edit-modal/documents-edit-modal.component';
import { WhiteboardPrintComponent } from './whiteboard/components/whiteboard-print/whiteboard-print.component';
import { ProductListWrapperComponent } from './components/product-list-wrapper/product-list-wrapper.component';
import { ProductListV2Component } from './components/product-list-v2/product-list-v2.component';
import { ProductGridViewV2Component } from './components/product-grid-view-v2/product-grid-view-v2.component';
import { ProductListViewV2Component } from './components/product-list-view-v2/product-list-view-v2.component';
import { ProductDetailWrapperComponent } from './components/product-detail-wrapper/product-detail-wrapper.component';
import { CloudPimProductFieldsComponent } from './components/cloud-pim-product-fields/cloud-pim-product-fields.component';
import { ProductDetailV2Component } from './components/product-detail-v2/product-detail-v2.component';
import { BuyButtonWrapperComponent } from './components/buy-button-wrapper/buy-button-wrapper.component';
import { BuyBtnMatrixComponent } from './components/buy-btn-matrix/buy-btn-matrix.component';
import { ProductListBannerComponent } from './components/product-list-banner/product-list-banner.component';
import { EdiModalCancelLineComponent } from './components/edi/edi-modal/edi-modal-cancel-line/edi-modal-cancel-line.component';
import { EdiModalChangeLineComponent } from './components/edi/edi-modal/edi-modal-change-line/edi-modal-change-line.component';
import { EdiGlnNumberTabsComponent } from './components/edi/edi-gln-number-tabs/edi-gln-number-tabs.component';
import { ProductGridCardV2Component } from './components/product-grid-card-v2/product-grid-card-v2.component';
import { ConceptGridCardV2Component } from './components/concept-grid-card-v2/concept-grid-card-v2.component';
import { BaseGridCardV2Component } from './components/base-grid-card-v2/base-grid-card-v2.component';
import { ConceptDetailV2Component } from './components/concept-detail-v2/concept-detail-v2.component';
import { ConceptDetailV2ProductListViewComponent } from './components/concept-detail-v2-product-list-view/concept-detail-v2-product-list-view.component';
import { EdiErrorMessagesComponent } from './components/edi/edi-error-messages/edi-error-messages.component';
import { ConceptDetailV2TopDetailsComponent } from './components/concept-detail-v2-top-details/concept-detail-v2-top-details.component';
import { EdiRowTableComponent } from './components/edi/edi-row-table/edi-row-table.component';
import { MatTableModule } from '@angular/material/table';
import { GungFlowDetailsSliderComponent } from './components/gung-flows/gung-flow-details/gung-flow-details-slider/gung-flow-details-slider.component';
import { DeliveryDatePickerBetaComponent } from './components/delivery-date-picker-beta/delivery-date-picker-beta.component';
import { TopSoldArticlesComponent } from './components/top-sold-articles/top-sold-articles.component';
import { FrontendSettingsComponent } from './components/frontend-settings/frontend-settings.component';
import { TopProductGridComponent } from './components/top-product-grid/top-product-grid.component';
import { TranslationManagementComponent } from './components/translation-management/translation-management.component';
import { AvailabilityTransactionsComponent } from './components/availability/availability-transactions/availability-transactions.component';
import { ContextTotalPriceComponent } from './components/checkout/utils/context-total-price/context-total-price.component';
import { BuyBtnInputComponent } from './components/buy-btn-input/buy-btn-input.component';
import { EdiSendStatusComponent } from './components/edi/edi-send-status/edi-send-status.component';
import { TranslationManagementListComponent } from './components/translation-management-list/translation-management-list.component';
import { TopProductsSummaryComponent } from './components/top-products/top-products-summary/top-products-summary.component';
import { GunglowDetailsDropsComponent } from './components/gung-flows/gung-flow-details/gung-flow-details-drops/gung-flow-details-drops.component';
import { TopProductsCreatePurchaseOrderModalComponent } from './components/top-products/top-products-create-purchase-order-modal/top-products-create-purchase-order-modal.component';
import { BarcodeScannerComponent } from './components/barcode-scanner/barcode-scanner.component';
import { ProductsInCartComponent } from './components/barcode-scanner/products-in-cart/products-in-cart.component';
import { UserDetailsManagedSupplierComponent } from './components/users/user-details/user-details-managed-supplier/user-details-managed-supplier.component';
import { EdiDetailErrorDisplayComponent } from './components/edi/edi-detail-error-display/edi-detail-error-display.component';
import { OpenPurchaseOrdersComponent } from './components/open-purchase-orders/open-purchase-orders.component';
import { OpenPurchaseOrdersCardListComponent } from './components/open-purchase-orders/open-purchase-orders-card-list/open-purchase-orders-card-list.component';
import { OrderConfirmationContactsModalComponent } from './components/open-purchase-orders/order-confirmation-contacts-modal/order-confirmation-contacts-modal.component';
import { OpenPurchaseOrdersDetailsComponent } from './components/open-purchase-orders/open-purchase-orders-details/open-purchase-orders-details.component';
import { MyCompanyComponent } from './components/my-company/my-company.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PimProductProxyIdComponent } from './components/pim/pim-product/pim-product-proxy-id/pim-product-proxy-id.component';
import { BarcodeProductDetailsComponent } from './components/barcode-scanner/barcode-product-details/barcode-product-details.component';
import { BarcodeQuickCheckoutComponent } from './components/barcode-scanner/barcode-quick-checkout/barcode-quick-checkout.component';
import { CustomerContactAddEditModalComponent } from './components/customer-contact-add-edit-modal/customer-contact-add-edit-modal.component';
import { CustomerContactsGungListComponent } from './components/customer-contacts-gung-list/customer-contacts-gung-list.component';
import { CustomerContactsGungCardListComponent } from './components/customer-contacts-gung-card-list/customer-contacts-gung-card-list.component';
import { ActivityInternalTodoComponent } from './components/activity-internal-todo/activity-internal-todo.component';
import { ActivityInternalTodoTableComponent } from './components/activity-internal-todo/activity-internal-todo-table/activity-internal-todo-table.component';
import { ScannerComponent } from './components/barcode-scanner/scanner/scanner.component';
import { BarcodeScannerWrapperComponent } from './components/barcode-scanner/barcode-scanner-wrapper.component';
import { PdfDownloadIndicatorComponent } from './components/pdf-download-indicator/pdf-download-indicator.component';
import { ProductService } from './services/products/product.service';
import { GungFlowService } from './services/gung-flow/gung-flow.service';
import {
  GungCommonModule,
  SchedulersMonitorComponent,
  DocumentTemplatesService as CommonDocumentTemplatesService,
  TemplateItemEditorComponent,
  GungLanguageConfigService
} from 'gung-common';
import { MatSortModule } from '@angular/material/sort';
import { UnderServiceNotificationComponent } from './components/under-service-notification/under-service-notification.component';
import { DocumentTemplatesService } from './services/document-templates/document-templates.service';
import {
  GungDynamicPdfModule,
  PdfProductService,
  PdfSelectedCustomerService,
  PdfFlowService,
  PdfProductExportService,
  PdfAuthService,
  ExportDragConfigService,
  PdfDownloadIndicatorService as dynamicPdfDownloadIndicatorService,
  GungDynamicPdfComponent,
  ExportPdfProductListComponent,
  DynamicProductExportListComponent,
  ExportPdfOrderProductListConfigService
} from 'gung-dynamic-pdf';
import { PimDimensionCreateModalComponent } from './components/pim-dimensions/pim-dimension-create-modal/pim-dimension-create-modal.component';
import { DynamicPdfProductListConfigService } from './services/dynamic-pdf/dynamic-pdf-product-list-config.service';
import { BackendInterceptor } from './services/backend-interceptor/backend-interceptor.service';
import { CreditBlockedModalComponent } from './components/creditCheck/credit-blocked-modal/credit-blocked-modal.component';
import { AssignUserSettingComponent } from './components/checkout/steps/assign-user-setting/assign-user-setting.component';
import { LoadSavedCartModalComponent } from './components/load-saved-cart-modal/load-saved-cart-modal.component';
import { PdfToCartSavedMappingsComponent } from './components/pdf-to-cart-saved-mappings/pdf-to-cart-saved-mappings.component';
import { SelectedCustomerService } from './services/selected-customer/selected-customer.service';
import { ProductExportService } from './services/product-export/product-export.service';
import { PdfDownloadIndicatorService } from './services/pdf-download-indicator.service';
import { PimProductPricesComponent } from './components/pim/pim-product/pim-product-prices/pim-product-prices.component';
import { DigitalAssetsModalComponent } from './components/digital-assets/digital-assets-modal/digital-assets-modal.component';
import { PriceLevelsComponent } from './components/price-levels/price-levels.component';
import { SystemSettingsComponent } from './components/actuator/system-settings/system-settings.component';
import { GungCompanyService } from './services/gung-company/gung-company.service';
import { gungColorToHsl } from './utils/gung-utils';
import { ProductConceptInlineSearchCardComponent } from './components/product-concept-inline-search-card/product-concept-inline-search-card.component';
import { InlineSearchProductConceptNavbarComponent } from './components/inline-search-product-concept-navbar/inline-search-product-concept-navbar.component';
import { SelectProductImageExportSizeModalComponent } from './components/select-product-image-export-size-modal/select-product-image-export-size-modal.component';
import { OfferListComponent } from './components/offer-list/offer-list.component';
import { OfferCardListComponent } from './components/offer-card-list/offer-card-list.component';
import { OfferDetailsComponent } from './components/offer-details/offer-details.component';
import { CustomerConditionsComponent } from './components/customer-conditions/customer-conditions.component';
import { GungMaintenanceComponent } from './components/gung-maintenance/gung-maintenance.component';
import { DeleteSavedCartModalComponent } from './components/delete-saved-card/delete-saved-card.component';
import { OfferConfirmationComponent } from './components/offer-confirmation/offer-confirmation.component';
import { AnonymousWithLoginOptionGuard } from './guards/anonymous-with-login-option/anonymous-with-login-option.guard';
import { CreateCustomerModalComponent } from './components/create-customer-modal/create-customer-modal.component';
import { UpdateCoverSheetImagesModalComponent } from './components/export-cover-sheets/update-cover-sheet-images-modal/update-cover-sheet-images-modal.component';
import { SchedulersMonitorListComponent } from './components/schedulers-monitor-list/schedulers-monitor-list.component';
import { SchedulersMonitorListViewComponent } from './components/schedulers-monitor-list-view/schedulers-monitor-list-view.component';
import { ReturnWarrantyComponent } from './components/return-warranty/return-warranty.component';
import { ReturnWarrantyListComponent } from './components/return-warranty/return-warranty-list/return-warranty-list.component';
import { ReturnWarrantyTableComponent } from './components/return-warranty/return-warranty-table/return-warranty-table.component';
import { MetaMultiSelectInputComponent } from './components/meta-multi-select-input/meta-multi-select-input.component';
import { UploadItemsFromExcelComponent } from './components/upload-items-from-excel/upload-items-from-excel.component';
import { ReturnWarrantyCreateViewModalComponent } from './components/return-warranty/return-warranty-create-view-modal/return-warranty-create-view-modal.component';
import { OrderFinderTableListComponent } from './components/order-finder-table-list/order-finder-table-list.component';
import { ConceptProductsModalComponent } from './components/concept-products-modal/concept-products-modal.component';
import { SavedCartProductModalComponent } from './components/saved-cart-product-modal/saved-cart-product-modal.component';
import { HomeSlideProductListTemplateComponent } from './components/custom-page/default-template/home-slide-product-list-template/home-slide-product-list-template.component';
import { PimTemplateItemEditorComponent } from './components/pim-templates/pim-template-item-editor/pim-template-item-editor.component';
import { PimTemplateItemEditorModalComponent } from './components/pim-templates/pim-template-item-editor-modal/pim-template-item-editor-modal.component';
import { PimTemplateCreateModalComponent } from './components/pim-templates/pim-template-create-modal/pim-template-create-modal.component';
import { SelectIntervalOrdersDatesModalComponent } from './components/select-interval-orders-dates-modal/select-interval-orders-dates-modal.component';
import { ChangeLogLevelComponent } from './components/actuator/change-log-level/change-log-level.component';
import { LanguageConfigService } from './services/language-config/language-config.service';
import { ConfigurationsEditorComponent } from './components/admin/configurations-editor/configurations-editor.component';
import { SupplierStartPageComponent } from './components/supplier/supplier-start-page/supplier-start-page.component';
import { SupplierWrapperComponent } from './components/supplier/supplier-wrapper/supplier-wrapper.component';
import { SupplierPoSplitModalComponent } from './components/supplier/supplier-po-split-modal/supplier-po-split-modal.component';
import { SupplierPoListTableViewComponent } from './components/supplier/supplier-po-list-table-view/supplier-po-list-table-view.component';
import { SupplierPoListComponent } from './components/supplier/supplier-po-list/supplier-po-list.component';
import { SupplierPoHistoryModalComponent } from './components/supplier/supplier-po-history-modal/supplier-po-history-modal.component';
import { SupplierPoDetailsModalComponent } from './components/supplier/supplier-po-details-modal/supplier-po-details-modal.component';
import { SupplierGridViewComponent } from './components/supplier/supplier-grid-view/supplier-grid-view.component';
import { CheckoutCartListComponent } from './components/checkout/steps/checkout-cart-list/checkout-cart-list.component';
import { ProductInlineAndCategoriesSearchComponent } from './components/product-inline-and-categories-search/product-inline-and-categories-search.component';
import { HtmlTemplateComponent } from './components/custom-page/default-template/html-template/html-template.component';
import { CustomPageHtmlEditorComponent } from './components/custom-page/custom-page-html-editor/custom-page-html-editor.component';
import { ProductIdsCardShowComponent } from './components/product-ids-card-show/product-ids-card-show.component';
import { GungViewer3dComponent } from './components/gung-viewer3d/gung-viewer3d.component';
import { GungViewer3dModalComponent } from './components/gung-viewer3d-modal/gung-viewer3d-modal.component';
import { GungViewer3dWrapperComponent } from './components/gung-viewer3d-wrapper/gung-viewer3d-wrapper.component';
import { NavbarSearchViewMoreListComponent } from './components/navbar-search-view-more-list/navbar-search-view-more-list.component';
import { EdiModalChangeQuantityComponent } from './components/edi/edi-modal/edi-modal-change-quantity/edi-modal-change-quantity.component';
import { SupplierCommentsModalComponent } from './components/supplier/supplier-comments-modal/supplier-comments-modal.component';
import { CheckoutCartDiscountMatrixModalComponent } from './components/checkout/steps/checkout-cart-list/checkout-cart-discount-matrix-modal/checkout-cart-discount-matrix-modal.component';
import { CheckoutAddRowModalComponent } from './components/checkout/steps/checkout-cart-list/checkout-add-row-modal/checkout-add-row-modal/checkout-add-row-modal.component';
import { CustomPageMissingComponent } from './components/custom-page-missing/custom-page-missing.component';
import { ActivityInternalGungCalendarComponent } from './components/activity-internal/activity-internal-gung-calendar/activity-internal-gung-calendar.component';
import { MapModalComponent } from './components/map/map-modal/map-modal.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { GungMapComponent } from './components/map/gung-map/gung-map.component';
import { OrderAnalyticsChartTableHorizontalComponent } from './components/order-analytics/order-analytics-chart-table-horizontal/order-analytics-chart-table-horizontal.component';
import { OrderAnalyticsStandaloneComponent } from './components/order-analytics/order-analytics-standalone/order-analytics-standalone.component';
import { ActuatorShopifyComponent } from './components/actuator/actuator-shopify/actuator-shopify.component';
import { ActivitiesCardListComponent } from './components/activity-hrp/activities-card-list/activities-card-list.component';
import { ActivitiesEditorModalComponent } from './components/activity-hrp/activities-editor-modal/activities-editor-modal.component';
import { ActivitiesListComponent } from './components/activity-hrp/activities-list/activities-list.component';
import { MatrixDiscountsComponent } from './components/matrix-discounts/matrix-discounts.component';
import { MatrixDiscountsCardListComponent } from './components/matrix-discounts-card-list/matrix-discounts-card-list.component';
import { MatrixDiscountModalComponent } from './components/matrix-discount-modal/matrix-discount-modal.component';
import { CarouselProductGridViewComponent } from './components/carousel-product-grid-view/carousel-product-grid-view.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CustomPageTextInputEditorModalComponent } from './components/custom-page/custom-page-text-input-editor-modal/custom-page-text-input-editor-modal.component';
import { EanReplaceModalComponent } from './components/ean-replace-modal/ean-replace-modal.component';
import { ItemsChangesUpdateModalComponent } from './components/items-changes-update-modal/items-changes-update-modal.component';
import { GungStandardFilterListDateUtilService } from './services/gung-standard-filter-list-date-util.service';
import {
  FeatureActivatedGuardWrapper,
  featureActivatedGuard
} from './guards/feature-activated/feature-activated.guard';
import { InactiveFeatureComponent } from './components/inactive-feature/inactive-feature.component';
import { ProductionOrdersListComponent } from './components/production-orders-list/production-orders-list.component';
import { StripePaymentStepComponent } from './components/stripe-payment-step/stripe-payment-step.component';
import { DynamicPdfOrderProductListConfigService } from './services/dynamic-pdf/dynamic-pdf-order-product-list-config.service';
import { ProductionOrderWrapperComponent } from './components/production-order-wrapper/production-order-wrapper.component';
import { ProductionOrderGungCalendarComponent } from './components/production-order-gung-calendar/production-order-gung-calendar.component';
import { BulkCheckoutWrapperComponent } from './components/bulk-checkout/bulk-checkout-wrapper/bulk-checkout-wrapper.component';
import { SelectSavedCartModalComponent } from './components/bulk-checkout/select-saved-cart-modal/select-saved-cart-modal.component';
import { BulkSummaryComponent } from './components/bulk-checkout/bulk-summary/bulk-summary.component';
import { BulkPlanComponent } from './components/bulk-checkout/bulk-plan/bulk-plan.component';
import { BulkCheckoutButtonsComponent } from './components/bulk-checkout/bulk-checkout-buttons/bulk-checkout-buttons.component';
import { LoggedInUserListComponent } from './components/logged-in-user-list/logged-in-user-list.component';
import { EanRepositoryCardListComponent } from './components/edi/ean-repository-card-list/ean-repository-card-list.component';
import { EdiEanRepositoryComponent } from './components/edi/edi-ean-repository/edi-ean-repository.component';
import { OrderConfirmConfigurationComponent } from './components/order-confirm-configuration/order-confirm-configuration.component';
import { EdiListPurchasedOrdersComponent } from './components/edi/edi-list-purchased-orders/edi-list-purchased-orders.component';
import { HubspotContactListComponent } from './components/hubspot-contact-list/hubspot-contact-list.component';
import { HubspotContactCardListComponent } from './components/hubspot-contact-card-list/hubspot-contact-card-list.component';
import { InactiveUsersNotificationConfigurationComponent } from './components/inactive-users-notification-configuration/inactive-users-notification-configuration.component';
import { EdiPurchasedOrderDetailComponent } from './components/edi/edi-purchased-order-detail/edi-purchased-order-detail.component';
import { CompanyTemplatesListComponent } from './components/company-templates-list/company-templates-list.component';
import { CompanyTemplatesListViewComponent } from './components/company-templates-list-view/company-templates-list-view.component';
import { CompanyTemplatesAddEditModalComponent } from './components/company-templates-add-edit-modal/company-templates-add-edit-modal.component';
import { SimpleProductListByIdsComponent } from './components/simple-product-list-by-ids/simple-product-list-by-ids.component';
import { RoutingUtilService } from './services/utils/routing-util.service';
import { RoutingUtilService as CommonRoutingUtilService } from 'gung-common';
import { EdiIncomingOrderResponseDetailComponent } from './components/edi/edi-incoming-order-response-detail/edi-incoming-order-response-detail.component';
import { EdiIncomingOrderResponseComponent } from './components/edi/edi-incoming-order-response/edi-incoming-order-response.component';
import { EdiPurchasedOrderCardListComponent } from './components/edi/edi-purchased-order-card-list/edi-purchased-order-card-list.component';
import { EdiIncomingDespatchAdvicesComponent } from './components/edi/edi-incoming-despatch-advices/edi-incoming-despatch-advices.component';
import { EdiIncomingDespatchAdviceDetailComponent } from './components/edi/edi-incoming-despatch-advice-detail/edi-incoming-despatch-advice-detail.component';
import { EdiIncomingDespatchCardListComponent } from './components/edi/edi-incoming-despatch-card-list/edi-incoming-despatch-card-list.component';
import { AdyenPaymentStepComponent } from './components/adyen-payment-step/adyen-payment-step.component';
import { AdyenPaymentFailedComponent } from './components/adyen-payment-failed/adyen-payment-failed.component';
import { MockrolesWarningComponent } from './components/mockroles-warning/mockroles-warning.component';
import { OrderAnalyticsProductTableComponent } from './components/order-analytics/order-analytics-product-table/order-analytics-product-table.component';
import { HomeImagesAndProductsTemplateComponent } from './components/custom-page/default-template/home-images-and-products-template/home-images-and-products-template.component';
import { ActuatorEnvironmentComponent } from './components/actuator/actuator-environment/actuator-environment.component';
import { ActuatorEnvironmentListComponent } from './components/actuator/actuator-environment/actuator-environment-list/actuator-environment-list.component';
import { GungSettingsComponent } from './components/gung-settings/gung-settings.component';
import { GungSettingsBaseViewComponent } from './components/gung-settings-base-view/gung-settings-base-view.component';
import { GungSettingsUsersViewComponent } from './components/gung-settings/gung-settings-users-view/gung-settings-users-view.component';
import { GungTitleService } from './services/gung-title.service';
import { MetaSelectInputHorizontalComponent } from './components/meta-select-input-horizontal/meta-select-input-horizontal.component';
import { AdyenPaymentSuccessfulComponent } from './components/adyen-payment-successful/adyen-payment-successful.component';
import { PimVariantsWrapperComponent } from './components/pim-variants-wrapper/pim-variants-wrapper.component';
import { PimVariantsProductComponent } from './components/pim-variants-product/pim-variants-product.component';
import { SupplierPoAllListComponent } from './components/supplier/supplier-po-all-list/supplier-po-all-list.component';
import { SupplierPoAllListTableViewComponent } from './components/supplier/supplier-po-all-list-table-view/supplier-po-all-list-table-view.component';
import { AdyenPaymentComponent } from './components/adyen-payment/adyen-payment.component';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { GlobalSearchProductDetailComponent } from './components/global-search-product-detail/global-search-product-detail.component';
import { GlobalSearchCardComponent } from './components/global-search-card/global-search-card.component';
import { ImageCarouselMinimalComponent } from './components/image-carousel-minimal/image-carousel-minimal.component';
import { PriceWrapperComponent } from './components/price-wrapper/price-wrapper.component';
import { MultiOrderExcelImportResultComponent } from './components/linesheet/multi-order-excel-import-result/multi-order-excel-import-result.component';
import { CustomerGungAddressCardListComponent } from './components/customer-gung-address-card-list/customer-gung-address-card-list.component';
import { CustomerAddGungAddressModalComponent } from './components/customer-add-gung-address-modal/customer-add-gung-address-modal.component';
import { CustomerGungAddressComponent } from './components/customer-gung-address/customer-gung-address.component';
import { FashionResetPasswordComponent } from './components/reset-password/fashion-reset-password/fashion-reset-password.component';
import { ResetPasswordWrapperComponent } from './components/reset-password/reset-password-wrapper/reset-password-wrapper.component';
import { MarketingMaterialsTemplateComponent } from './components/custom-page/default-template/marketing-materials-template/marketing-materials-template.component';
import { setColors } from './utils/set-bootstrap-colors';
import { MyCompanyFooterComponent } from './components/my-company/my-company-footer/my-company-footer.component';
import { GungAppComponent } from './components/gung-main-layout/gung-app/gung-app.component';
import { GungNavbarComponent } from './components/gung-main-layout/gung-navbar/gung-navbar.component';
import { GungMainMenuComponent } from './components/gung-main-layout/gung-main-menu/gung-main-menu.component';
import { GungExpandMenuComponent } from './components/gung-main-layout/gung-expand-menu/gung-expand-menu.component';
import { CustomerListTableComponent } from './components/customer-list-table/customer-list-table.component';
import { DeliveryLocationsComponent } from './components/checkout/steps/delivery-locations/delivery-locations.component';
import { AssignDefaultsComponent } from './components/checkout/steps/assign-defaults/assign-defaults.component';
import { SummarizeOrderComponent } from './components/checkout/steps/summarize-order/summarize-order.component';
import { OrderTermsComponent } from './components/checkout/steps/order-terms/order-terms.component';
import { GungNavbarCartComponent } from './components/gung-main-layout/gung-navbar-cart/gung-navbar-cart.component';
import { HomeHeroBannerAndCategoriesTemplateComponent } from './components/custom-page/default-template/home-hero-banner-and-categories-template/home-hero-banner-and-categories-template.component';
import { HomeHeroBannerProductCardsComponent } from './components/custom-page/default-template/home-hero-banner-and-categories-template/home-hero-banner-product-cards/home-hero-banner-product-cards.component';
import { AdyenRedirectResultComponent } from './components/adyen-redirect-result/adyen-redirect-result.component';
import { AdyenOrderFailureComponent } from './components/adyen-order-failure/adyen-order-failure.component';
import { AdyenPayInvoiceComponent } from './components/adyen-pay-invoice/adyen-pay-invoice.component';
import { CustomerCustomTableBodyComponent } from './components/customer-list-table/customer-custom-table-body/customer-custom-table-body.component';
import { GungSettingsFortnoxOfferConfirmationComponent } from './components/gung-settings/gung-settings-fortnox-offer-confirmation/gung-settings-fortnox-offer-confirmation.component';
import { GungSettingsFortnoxOrderConfirmationComponent } from './components/gung-settings/gung-settings-fortnox-order-confirmation/gung-settings-fortnox-order-confirmation.component';
import { GungSettingsSecurityViewComponent } from './components/gung-settings/gung-settings-security-view/gung-settings-security-view.component';
import { GungSettingsCustomerTemplatesComponent } from './components/gung-settings/gung-settings-customer-templates/gung-settings-customer-templates.component';
import { GungSettingsConfigOrderViewComponent } from './components/gung-settings/gung-settings-config-order-view/gung-settings-config-order-view.component';
import { GungSettingsConfigOfferViewComponent } from './components/gung-settings/gung-settings-config-offer-view/gung-settings-config-offer-view.component';
import { GungSettingsNavigationViewComponent } from './components/gung-settings/gung-settings-general-view/gung-settings-navigation-view.component';
import { HomeNavigationConfigComponent } from './components/home-navigation-config/home-navigation-config.component';
import { EditProductModalComponent } from './components/edit-product-modal/edit-product-modal.component';
import { MenuConfigurationMatrixComponent } from './components/menu-configuration-matrix/menu-configuration-matrix.component';
import { SelectedCustomerRedirectGuard } from './guards/selected-customer-redirect/selected-customer-redirect.guard';
import { ArticlesFirstSubassortmentComponent } from './components/articles-first-subassortment/articles-first-subassortment.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: HomeComponent,
    data: {
      gungTitle: '<GUNGTRANSLATE>HOME</GUNGTRANSLATE>'
    }
  },
  {
    path: 'products/:assortmentId',
    canActivate: [AuthGuard],
    component: ProductListWrapperComponent,
    data: {
      gungTitle: '<GUNG>assortment.name</GUNG>',
      extraFetch: { assortment: 'assortmentId' }
    }
  },
  {
    path: 'products',
    canActivate: [AuthGuard],
    component: ProductListWrapperComponent
  },
  {
    path: 'articles',
    canActivate: [AuthGuard],
    children: [
      {
        path: '**',
        canActivate: [AuthGuard],
        component: AssortmentTreeNavigationComponent,
        data: {
          gungTitle: '<GUNG>assortment.name</GUNG>',
          extraFetch: { assortmentTree: '*' }
        }
      }
    ]
  },
  {
    path: 'categories/:assortmentId',
    canActivate: [AuthGuard],
    component: ProductListComponent,
    data: {
      gungTitle: '<GUNG>assortment.name</GUNG>',
      extraFetch: { assortment: 'assortmentId' }
    }
  },
  {
    path: 'products-export',
    canActivate: [AuthGuard],
    component: ProductExportListComponent
  },
  {
    path: 'products2',
    canActivate: [AuthGuard],
    component: NavbarCartComponent,
    data: {
      rolesAllowed: ['Super']
    }
  },
  {
    path: 'view-more',
    canActivate: [AuthGuard],
    component: NavbarSearchViewMoreListComponent,
    data: {
      gungTitle: '<GUNGTRANSLATE>VIEW_MORE</GUNGTRANSLATE>'
    }
  },
  {
    path: 'order-confirmation/:contextId',
    canActivate: [AuthGuard],
    component: OrderConfirmationComponent
  },
  {
    path: 'send-counting-transaction/:contextId',
    canActivate: [AuthGuard],
    component: SendCountingTransactionComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    data: {
      gungTitle: '<GUNGTRANSLATE>UNAUTHORIZED</GUNGTRANSLATE>'
    }
  },
  {
    path: 'login',
    component: LoginWrapperComponent,
    data: {
      gungTitle: '<GUNGTRANSLATE>LOGIN</GUNGTRANSLATE>'
    }
  },
  {
    path: 'oauth-login',
    component: LoginWrapperComponent,
    data: {
      gungTitle: '<GUNGTRANSLATE>LOGIN</GUNGTRANSLATE>'
    }
  },
  {
    path: 'orders',
    canActivate: [AuthGuard],
    component: OrderListComponent,
    data: {
      gungTitle: '<GUNGTRANSLATE>ORDERS</GUNGTRANSLATE>'
    }
  },
  {
    path: 'orders/:orderId',
    canActivate: [AuthGuard],
    component: OrderDetailsComponent
  },
  {
    path: 'offers',
    canActivate: [AuthGuard],
    component: OfferListComponent,
    data: {
      gungTitle: '<GUNGTRANSLATE>OFFERS</GUNGTRANSLATE>'
    }
  },
  {
    path: 'offers/:offerId',
    canActivate: [AuthGuard],
    component: OfferDetailsComponent
  },
  {
    path: 'offer-confirmation/:contextId',
    canActivate: [AuthGuard],
    component: OfferConfirmationComponent
  },
  {
    path: 'customers',
    canActivate: [AuthGuard],
    component: CustomerListTableComponent,
    data: {
      gungTitle: '<GUNGTRANSLATE>CUSTOMERS</GUNGTRANSLATE>'
    }
  },
  {
    path: 'customers/:customerId',
    canActivate: [AuthGuard],
    component: CustomerDetailsComponent,
    data: {
      gungTitle: '<GUNG>selectedCustomer.name</GUNG>'
    }
  },
  {
    // Simple route that will redirect to the selected customer page if we have a selected customer, otherwise we will
    // redirect to the home page.
    path: 'selected-customer',
    canActivate: [AuthGuard, SelectedCustomerRedirectGuard],
    // Below is simple a fallback if something goes wrong above, but we should always have a selected customer, =>
    // always get redirected.
    component: HomeComponent
  },
  {
    path: 'checkout',
    canActivate: [AuthGuard],
    component: CheckoutWrapperComponent,
    data: {
      gungTitle: '<GUNGTRANSLATE>CHECKOUT</GUNGTRANSLATE>'
    }
  },
  {
    path: 'product/:productId',
    canActivate: [AuthGuard],
    component: ProductDetailWrapperComponent,
    data: {
      gungTitle: '<GUNG>product.name</GUNG>',
      extraFetch: { product: 'productId' }
    }
  },
  {
    path: 'open-orders',
    canActivate: [AuthGuard],
    component: OpenOrdersComponent,
    data: {
      gungTitle: '<GUNGTRANSLATE>OPEN_ORDERS</GUNGTRANSLATE>'
    }
  },
  {
    path: 'quotes',
    canActivate: [AuthGuard],
    component: QuotesComponent,
    data: {
      gungTitle: '<GUNGTRANSLATE>QUOTES</GUNGTRANSLATE>'
    }
  },
  {
    path: 'invoices',
    canActivate: [AuthGuard],
    component: InvoiceListComponent,
    data: {
      gungTitle: '<GUNGTRANSLATE>INVOICES</GUNGTRANSLATE>'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      gungTitle: '<GUNGTRANSLATE>FORGOT_PASSWORD</GUNGTRANSLATE>'
    }
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordWrapperComponent
  },
  {
    path: 'top-products',
    canActivate: [AuthGuard],
    component: TopSoldArticlesComponent,
    data: {
      gungTitle: '<GUNGTRANSLATE>TOP_PRODUCTS</GUNGTRANSLATE>'
    }
  },
  {
    path: 'admin/configurations',
    component: ConfigurationsEditorComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin/manage-users',
    component: ManageUsersWrapperComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin/users-details/:username',
    component: UserDetailsComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin/custom-page-editor',
    component: CustomPageEditorComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin/gung-flows',
    component: GungFlowsWrapperComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin/gung-flow-details/:flowId',
    component: GungFlowDetailsComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'categories',
    component: CustomPageDisplayComponent,
    resolve: {
      pageComponent: CustomPageResolverService
    },
    data: {
      pageName: 'homeassortment'
      // pageComponent: HomeAssortmentTemplateComponent
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'page/:id',
    component: CustomPageDisplayComponent,
    resolve: {
      pageComponent: CustomPageResolverService
    },
    data: {
      pageName: undefined
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'sales-dashboard',
    redirectTo: 'customers',
    pathMatch: 'full'
  },
  {
    path: 'sales-dashboard/import/budget',
    redirectTo: 'customers',
    pathMatch: 'full'
  },
  {
    path: 'products-search',
    component: ProductFastSearchComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/schedulers-monitor',
    component: SchedulersMonitorListComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin/manage-caches',
    component: ManageCachesComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin/document-templates/template-item-editor',
    component: TemplateItemEditorComponent,
    canActivate: [AuthGuard, AdminGuard],
    canDeactivate: [
      (
        component: TemplateItemEditorComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
      ) => !component.hasUnsavedChanges(currentRoute, currentState, nextState)
    ]
  },
  {
    path: 'admin/pim-templates/pim-template-item-editor',
    component: PimTemplateItemEditorComponent,
    canActivate: [AuthGuard, AdminGuard],
    canDeactivate: [
      (
        component: PimTemplateItemEditorComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
      ) => !component.hasUnsavedChanges(currentRoute, currentState, nextState)
    ]
  },
  {
    path: 'admin/documents',
    component: DocumentsComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin/availability-conditions',
    component: AvailabilityConditionsComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'favourites',
    component: FavouriteProductListComponent,
    canActivate: [AuthGuard],
    data: {
      gungTitle: '<GUNGTRANSLATE>FAVOURITES</GUNGTRANSLATE>'
    }
  },
  {
    path: 'carts',
    component: SavedCartListComponent,
    canActivate: [AuthGuard],
    data: {
      gungTitle: '<GUNGTRANSLATE>CARTS</GUNGTRANSLATE>'
    }
  },
  {
    path: 'saved-quotes',
    component: SavedQuoteListComponent,
    canActivate: [AuthGuard],
    data: {
      gungTitle: '<GUNGTRANSLATE>SAVED_QUOTES</GUNGTRANSLATE>'
    }
  },
  {
    path: 'admin/config/export-cover-sheets',
    component: ExportCoverSheetsComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin/import-linesheet',
    component: ImportLinesheetComponent,
    canActivate: [AuthGuard, SalesGuard]
  },
  {
    path: 'admin/upload-items',
    component: UploadItemsFromExcelComponent,
    canActivate: [AuthGuard, SalesGuard]
  },
  {
    path: 'admin/shipping-charges',
    component: ShippingChargesManagerComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin/news',
    component: NewsEditorListComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'customizable-products',
    component: ProductLogoApplicatorListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customize-product/:productId',
    component: ProductDetailLogoApplicatorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customize-product/:productId/:applicationId/:edit?',
    component: ProductDetailLogoApplicatorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'payment-failure',
    canActivate: [AuthGuard],
    component: StripePaymentFailureComponent
  },
  {
    path: 'pay-invoice/:invoiceId',
    canActivate: [AuthGuard, featureActivatedGuard],
    component: AdyenPayInvoiceComponent,
    data: {
      featureId: 'adyen',
      minimumGungBaseVersion: '3.0.16'
    }
  },
  {
    path: 'payment-successful-adyen/:adyenOrderId',
    canActivate: [AuthGuard, featureActivatedGuard],
    component: AdyenPaymentSuccessfulComponent,
    data: {
      featureId: 'adyen',
      minimumGungBaseVersion: '3.0.16'
    }
  },
  {
    path: 'payment-failure-adyen',
    canActivate: [AuthGuard, featureActivatedGuard],
    component: AdyenPaymentFailedComponent,
    data: {
      featureId: 'adyen',
      minimumGungBaseVersion: '3.0.16'
    }
  },
  {
    path: 'order-failure-adyen',
    canActivate: [AuthGuard, featureActivatedGuard],
    component: AdyenOrderFailureComponent,
    data: {
      featureId: 'adyen',
      minimumGungBaseVersion: '3.0.16'
    }
  },
  {
    path: 'adyen-redirect-result',
    canActivate: [AuthGuard, featureActivatedGuard],
    component: AdyenRedirectResultComponent,
    data: {
      featureId: 'adyen',
      minimumGungBaseVersion: '3.0.16'
    }
  },
  {
    path: 'payment-success',
    canActivate: [AuthGuard],
    component: StripePaymentSuccessComponent
  },
  {
    path: 'production-orders',
    component: ProductionOrderWrapperComponent,
    canActivate: [AuthGuard, AdminGuard, featureActivatedGuard],
    data: {
      featureId: 'gung-production',
      minimumGungBaseVersion: '3.0.16'
    }
  },
  {
    path: 'pim',
    component: PimWrapperComponent,
    canActivate: [AuthGuard, AdminGuard, featureActivatedGuard],
    data: {
      featureId: 'gung-internal-pim',
      minimumGungBaseVersion: '3.0.11'
    }
  },
  {
    path: 'pim/:productId',
    component: PimProductComponent,
    canActivate: [AuthGuard, AdminGuard, featureActivatedGuard],
    canDeactivate: [
      (
        component: PimProductComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
      ) => !component.hasUnsavedChanges(currentRoute, currentState, nextState)
    ],
    data: {
      featureId: 'gung-internal-pim',
      minimumGungBaseVersion: '3.0.11'
    }
  },
  {
    path: 'pim-variants',
    component: PimVariantsWrapperComponent,
    canActivate: [AuthGuard, AdminGuard, featureActivatedGuard],
    data: {
      featureId: 'gung-internal-pim',
      minimumGungBaseVersion: '3.0.11'
    }
  },
  {
    path: 'pim-variants/:productId',
    component: PimProductComponent,
    canActivate: [AuthGuard, AdminGuard, featureActivatedGuard],
    canDeactivate: [
      (
        component: PimProductComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
      ) => !component.hasUnsavedChanges(currentRoute, currentState, nextState)
    ],
    data: {
      featureId: 'gung-internal-pim',
      minimumGungBaseVersion: '3.0.11'
    }
  },
  {
    path: 'delivery-location',
    component: DeliveryLocationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'inactive-feature/:id',
    component: InactiveFeatureComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/edi',
    component: EdiWrapperComponent,
    canActivate: [AuthGuard, AdminGuard, featureActivatedGuard],
    data: {
      featureId: 'gung-feature-edi',
      minimumGungBaseVersion: '3.0.10'
    },
    children: [
      {
        path: 'gln-numbers',
        component: EdiGlnNumbersComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'messages',
        component: EdiMessagesComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'import-xml',
        component: EdiImportXmlComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'orders',
        component: EdiListOrdersComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'orders-bulk-edit',
        component: EdiOrderBulkEditComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'invoices',
        component: EdiInvoicesComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'invoices/:id',
        component: EdiInvoiceDetailComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'orders/:id',
        component: EdiOrderDetailComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'dispatch-advices',
        component: EdiDispatchAdvicesComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'dispatch-advices/:id',
        component: EdiDispatchAdviceDetailComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'incoming-despatch-advices',
        component: EdiIncomingDespatchAdvicesComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'incoming-despatch-advices/:id',
        component: EdiIncomingDespatchAdviceDetailComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'order-responses',
        component: EdiOrderResponseComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'order-responses/:id',
        component: EdiOrderResponseDetailComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'incoming-order-responses',
        component: EdiIncomingOrderResponseComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'incoming-order-responses/:id',
        component: EdiIncomingOrderResponseDetailComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'purchased-orders',
        component: EdiListPurchasedOrdersComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'purchased-orders/:id',
        component: EdiPurchasedOrderDetailComponent,
        canActivate: [AuthGuard, AdminGuard]
      }
    ]
  },
  {
    path: 'admin/ean',
    component: EdiWrapperComponent,
    canActivate: [AuthGuard, AdminGuard],
    children: [
      {
        path: 'ean-repository',
        component: EdiEanRepositoryComponent,
        canActivate: [AuthGuard, AdminGuard]
      }
    ]
  },
  {
    path: 'pim-concept/:assortmentId',
    component: PimConceptWrapperComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'return-warranty',
    component: ReturnWarrantyComponent,
    canActivate: [AuthGuard, AdminGuard, featureActivatedGuard],
    data: {
      featureId: 'gung-warranty-claims',
      minimumGungBaseVersion: '3.0.15'
    }
  },
  {
    path: 'digital-assets/management',
    canActivate: [AuthGuard, AdminGuard, featureActivatedGuard],
    data: {
      featureId: 'gung-feature-digitalassets',
      minimumGungBaseVersion: '3.0.15'
    },
    children: [
      {
        path: '**',
        component: DigitalAssetsWrapperComponent
      }
    ]
  },
  {
    path: 'digital-assets',
    canActivate: [AuthGuard, featureActivatedGuard],
    data: {
      featureId: 'gung-feature-digitalassets',
      minimumGungBaseVersion: '3.0.15'
    },
    children: [
      {
        path: '**',
        component: DigitalAssetsWrapperComponent
      }
    ]
  },
  {
    path: 'news',
    component: NewsPageListComponent,
    canActivate: [AuthGuard],
    data: {
      gungTitle: '<GUNGTRANSLATE>NEWS</GUNGTRANSLATE>'
    }
  },
  {
    path: 'news/:id',
    canActivate: [AuthGuard],
    component: NewsPageSingleComponent
  },
  {
    path: 'admin/account-request/manage',
    canActivate: [AuthGuard, AdminGuard],
    component: AccountRequestsManageComponent
  },
  {
    path: 'admin/pim-metadata',
    canActivate: [AuthGuard, AdminGuard],
    component: PimMetadataWrapperComponent
  },
  {
    path: 'admin/pim-dimensions',
    canActivate: [AuthGuard, AdminGuard],
    component: PimDimensionsWrapperComponent
  },
  {
    path: 'admin/related-brands',
    canActivate: [AuthGuard],
    component: BrandsManagerComponent
  },
  {
    path: 'admin/related-brands/:brandId',
    component: BrandEditComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin/related-brands/new-brand',
    component: BrandEditComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin/actuator',
    component: ActuatorWrapperComponent,
    canActivate: [AuthGuard, AdminGuard, ActuatorGuard]
  },
  {
    path: 'activity-internal',
    component: ActivityInternalWrapperComponent,
    canActivate: [AuthGuard, featureActivatedGuard],
    data: {
      featureId: 'gung-activities-internal',
      minimumGungBaseVersion: '3.0.13'
    }
  },
  {
    path: 'admin/assortment',
    component: AssortmentManagementComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin/discount-codes',
    component: DiscountCodesComponent,
    canActivate: [AuthGuard, AdminGuard, featureActivatedGuard],
    data: {
      featureId: 'gung-discount-codes',
      minimumGungBaseVersion: '3.0.17'
    }
  },
  {
    path: 'admin/discount-codes/:code',
    component: DiscountCodesComponent,
    canActivate: [AuthGuard, AdminGuard, featureActivatedGuard],
    data: {
      featureId: 'gung-discount-codes',
      minimumGungBaseVersion: '3.0.17'
    }
  },
  {
    path: 'quickorder',
    component: QuickorderComponent,
    canActivate: [AuthGuard],
    data: {
      gungTitle: '<GUNGTRANSLATE>QUICKORDER</GUNGTRANSLATE>'
    }
  },
  {
    path: 'whiteboard',
    component: WhiteboardGridComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'whiteboard/:id',
    component: WhiteboardGridComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'whiteboard/:id/print',
    component: WhiteboardPrintComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'products-whiteboard/:whiteboardId',
    component: WhiteboardOrderProductListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'whiteboard-management',
    component: WhiteboardManagementComponent
  },
  {
    path: 'concept/:productId',
    canActivate: [AuthGuard],
    component: ConceptDetailV2Component
  },
  {
    path: 'actuator/application-settings',
    canActivate: [ActuatorGuard],
    component: FrontendSettingsComponent
  },
  {
    path: 'admin/translations',
    canActivate: [AuthGuard, AdminGuard],
    component: TranslationManagementListComponent
  },
  {
    path: 'barcode-scanner',
    component: BarcodeScannerWrapperComponent,
    canActivate: [AuthGuard, featureActivatedGuard],
    data: {
      featureId: 'gung-barcode-scanner',
      minimumGungBaseVersion: '3.0.14'
    }
  },
  {
    path: 'open-purchase-orders',
    component: OpenPurchaseOrdersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'open-purchase-orders/:orderId',
    component: OpenPurchaseOrdersDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/my-company',
    component: MyCompanyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'activity-internal-todo',
    component: ActivityInternalTodoComponent,
    canActivate: [AuthGuard, featureActivatedGuard],
    data: {
      featureId: 'gung-activities-internal',
      minimumGungBaseVersion: '3.0.13'
    }
  },
  {
    path: 'maintenance',
    component: GungMaintenanceComponent
  },
  {
    path: 'supplier-portal',
    canActivate: [AuthGuard],
    component: SupplierStartPageComponent
  },
  {
    path: 'suppliers',
    canActivate: [AuthGuard],
    component: SupplierWrapperComponent
  },
  {
    path: 'suppliers-purchase-order/view-all',
    canActivate: [AuthGuard],
    component: SupplierPoAllListComponent,
    pathMatch: 'full'
  },
  {
    path: 'suppliers-purchase-order/:purchaseOrderType',
    canActivate: [AuthGuard],
    component: SupplierPoListComponent
  },
  {
    path: 'admin/matrix-discounts',
    component: MatrixDiscountsComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'export-pdf',
    component: GungDynamicPdfComponent,
    canActivate: [AuthGuard, featureActivatedGuard],
    data: {
      featureId: 'gung-dynamic-pdf',
      minimumGungBaseVersion: '3.0.13'
    }
  },
  {
    path: 'export-pdf/new',
    component: DynamicProductExportListComponent,
    canActivate: [AuthGuard, featureActivatedGuard],
    data: {
      featureId: 'gung-dynamic-pdf',
      minimumGungBaseVersion: '3.0.13'
    }
  },
  {
    path: 'export-pdf/:id',
    component: DynamicProductExportListComponent,
    canActivate: [AuthGuard, featureActivatedGuard],
    data: {
      featureId: 'gung-dynamic-pdf',
      minimumGungBaseVersion: '3.0.13'
    }
  },
  {
    path: 'products-export-pdf/:id',
    component: ExportPdfProductListComponent,
    canActivate: [AuthGuard, featureActivatedGuard],
    data: {
      featureId: 'gung-dynamic-pdf',
      minimumGungBaseVersion: '3.0.13'
    }
  },
  {
    /*
    This route is used only to navigate to a different route than currently, and then back. Previously we used / as
    that route, however since some customers have routing logic there, that routing logic took the upper hand, causing
    us to get routed to a different route than intended.

    Below is one example of what we do with this route.
    this.router.navigate(['/no-operation'], { skipLocationChange: true }).then(() => {
      this.router.navigate(['/products'], { queryParams: { search: 'DEFAULT__:__' + value } });
    });
     */
    path: 'no-operation',
    component: ProductListWrapperComponent,
    canActivate: [AnonymousWithLoginOptionGuard]
  },
  {
    path: 'replenishment',
    loadChildren: () => import('./modules/replenishment/replenishment.module').then(m => m.ReplenishmentModule)
  },
  {
    path: 'report-central',
    loadChildren: () => import('./modules/report-central/report-central.module').then(m => m.ReportCentralModule)
  },
  {
    path: 'bankgiro',
    loadChildren: () => import('./modules/bankgiro/bankgiro.module').then(m => m.BankgiroModule)
  },
  {
    path: 'catalogues',
    loadChildren: () => import('./modules/catalogues/catalogues.module').then(m => m.CataloguesModule)
  },
  { path: 'cpq', loadChildren: () => import('./modules/cpq/cpq.module').then(m => m.CpqModule) },
  {
    path: 'bulk-order',
    component: BulkCheckoutWrapperComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/order-portal',
    loadChildren: () => import('./modules/order-portal/order-portal.module').then(m => m.OrderPortalModule)
  },
  {
    path: 'planogram',
    loadChildren: () => import('./modules/planogram/planogram.module').then(m => m.PlanogramModule)
  },
  {
    path: 'warehouse-management',
    loadChildren: () =>
      import('./modules/production-management/production-management.module').then(m => m.ProductionManagementModule),
    canActivate: [AuthGuard, featureActivatedGuard],
    data: {
      featureId: 'gung-production',
      minimumGungBaseVersion: '1.0.0'
    }
  },
  {
    path: 'admin/settings',
    component: GungSettingsComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'articles-first-subassortment',
    component: ArticlesFirstSubassortmentComponent,
    canActivate: [AuthGuard]
  },
  // Wildcard route to redirect if page does not exist, should probably add "PageNotExistCompoent" here
  // This MUST be the last route
  {
    path: '**',
    redirectTo: ''
  }
];
@NgModule({
  declarations: [
    ProductLogoApplicatorListComponent,
    EdiEanRepositoryComponent,
    ProductListComponent,
    ProductCardComponent,
    ProductExportListComponent,
    LoginComponent,
    HomeComponent,
    NavbarComponent,
    NavbarCartComponent,
    OrderListComponent,
    OrderCardListComponent,
    CustomerListComponent,
    CustomerListCardComponent,
    BuyBtnComponent,
    CustomStepperComponent,
    CartComponent,
    CartItemComponent,
    CartSummaryComponent,
    CustomerDetailsComponent,
    OrderDetailsComponent,
    ProductDetailComponent,
    UnauthorizedComponent,
    ThankYouComponent,
    MetaSelectInputComponent,
    LangSelectComponent,
    DeliveryDatePickerComponent,
    AppWrapperComponent,
    GungCurrencyPipe,
    CheckoutStepComponent,
    CheckoutWrapperComponent,
    GungCurrencyPipe,
    StepIndicatorComponent,
    ButtonsComponent,
    InitCheckoutObjectComponent,
    CartTotalStepComponent,
    AssignOrderRowsComponent,
    OrderConfirmationComponent,
    CustomerSelectDropdownComponent,
    InvoiceListComponent,
    InvoiceCardListComponent,
    CustomerContactListComponent,
    CustomerContactCardListComponent,
    CustomerUserCardListComponent,
    CustomerUserListComponent,
    GungDateOutputComponent,
    GungMetaOutputComponent,
    CartSalesStepComponent,
    DiscountInputComponent,
    MyCustomPagesComponent,
    CartListLayoutComponent,
    SafeHtmlPipe,
    ProductListByIdsComponent,
    OpenOrdersComponent,
    OpenOrderCardListComponent,
    QuotesComponent,
    QuotesCardListComponent,
    OpenOrderTableComponent,
    QuotesTableComponent,
    InvoiceTableComponent,
    ProductPaginationListComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ProductFastSearchComponent,
    ProductFastsearchCardComponent,
    ProductFastsearchDetailComponent,
    AvailabilityComponent,
    LoginModalComponent,
    ProductSelectDropdownComponent,
    ConfirmationEmailsComponent,
    FlowSelectComponent,
    AssignFlowSettingComponent,
    SavedCartTableComponent,
    SavedCartListComponent,
    SaveCartModalComponent,
    ProductExportCardComponent,
    ProductExportTableComponent,
    QuantityValidationStepComponent,
    CustomPageEditorComponent,
    HomeDefaultTemplateComponent,
    CustomPageDisplayComponent,
    CustomPageDirectiveDirective,
    CustomPageEditorModalComponent,
    GungDataExportConfirmModalComponent,
    PriceComponent,
    HomeAssortmentTemplateComponent,
    HomeFastSearchTemplateComponent,
    LoginWrapperComponent,
    FashionLoginComponent,
    NavbarLoginComponent,
    ProductGridViewComponent,
    EmailRecipientComponent,
    ManageUsersWrapperComponent,
    UserTableComponent,
    UserDetailsComponent,
    UserDetailsCustomersComponent,
    UserDetailsMultistocksComponent,
    UserDetailsRolesComponent,
    UserDetailsGroupsComponent,
    CreateUserModalComponent,
    UserDetailsCustomersTableComponent,
    DeleteUserModalComponent,
    ImportUsersComponent,
    ManageCachesComponent,
    ProductGridViewComponent,
    UserDetailsUserComponent,
    DocumentsComponent,
    DocumentsUploadModalComponent,
    FavouriteProductIconComponent,
    FavouriteProductListComponent,
    GungDataExportPricatConfirmModalComponent,
    ProductDetailMatrixModalComponent,
    ProductMatrixComponent,
    ProductMatrixSummaryElementComponent,
    ProductMatrixWrapperInputElementComponent,
    ProductMatrixElementSimplifiedAvailabilityComponent,
    ProductMatrixInputElementComponent,
    ProductMatrixElementAvailabilityModalComponent,
    GungFlowsWrapperComponent,
    GungFlowDetailsComponent,
    CreateGungFlowModalComponent,
    GungFlowsTableComponent,
    GungFlowDetailsBasicSettingsComponent,
    GungFlowDetailsProductSettingsComponent,
    GungFlowDetailsOrderSettingsComponent,
    ExportCoverSheetsComponent,
    NewCoverSheetModalComponent,
    OrderChangeCustomerConfirmationModalComponent,
    ImportLinesheetComponent,
    AvailabilityModalComponent,
    CreateQuotationModalComponent,
    SavedQuoteListComponent,
    SavedQuoteTableComponent,
    BackButtonComponent,
    SideButtonsComponent,
    FooterComponent,
    CartListComponent,
    RequestAccountComponent,
    ProductLogoApplicatorGridViewComponent,
    ProductDetailLogoApplicatorComponent,
    CartLogoApplicatorModalComponent,
    BreadcrumbComponent,
    AssortmentTreeNavigationComponent,
    AssortmentTreeListComponent,
    AssortmentTreeListViewComponent,
    ConceptTreeListComponent,
    ConceptTreeListViewComponent,
    ProductTreeListComponent,
    ProductTreeListViewComponent,
    ConceptDetailComponent,
    ConceptDetailProductListViewComponent,
    ImageCarouselComponent,
    HomeAssortmentNewTemplateComponent,
    StripePaymentFailureComponent,
    StripePaymentSuccessComponent,
    RemoveBlockedProductsStepComponent,
    PimWrapperComponent,
    PimGridViewComponent,
    PimProductComponent,
    PimCreateModelModalComponent,
    PimImportExcelModalComponent,
    PimUploadModalComponent,
    PimProductBasicInfoComponent,
    PimProductAttributesComponent,
    PimProductImagesComponent,
    PimProductDocumentsComponent,
    PimProductRelatedProductsComponent,
    PimProductVariantsComponent,
    ProductTreeTableViewComponent,
    ConceptTreeTableViewComponent,
    PdfToCartComponent,
    FileDropDirective,
    HomeFashionTemplateComponent,
    UserDetailsSubassortmentComponent,
    PimUploadExcelModalComponent,
    UserDetailsOrderParamsComponent,
    DeliveryLocationComponent,
    DeliveryLocationListCardComponent,
    EdiWrapperComponent,
    EdiGlnNumbersComponent,
    EdiMessagesComponent,
    EdiGlnNumbersTableViewComponent,
    EdiMessagesTableViewComponent,
    EdiGlnNumbersEditModalComponent,
    EdiInvoiceModalComponent,
    EdiDespatchAdviceModalComponent,
    ProductInlineSearchComponent,
    ProductInlineSearchCardComponent,
    AvailabilityConditionsComponent,
    CreateAvailabilityConditionModalComponent,
    PimConceptWrapperComponent,
    PimConceptGridViewComponent,
    EdiImportXmlComponent,
    OrderSubmitErrorComponent,
    DigitalAssetsGridViewComponent,
    DigitalAssetsManagementItemComponent,
    DigitalAssetsWrapperComponent,
    DigitalAssetsFolderGridViewComponent,
    NewsPageTableComponent,
    NewsPageListComponent,
    NewsEditorModalComponent,
    NewsEditorListComponent,
    NewsEditorTableComponent,
    NewsModalComponent,
    GungFlowDetailsHtmlComponent,
    AccountRequestsManageComponent,
    AccountRequestsCardListComponent,
    PimMetadataCardListComponent,
    PimMetadataWrapperComponent,
    PimMetadataModalComponent,
    PimDimensionsModalComponent,
    PimDimensionsWrapperComponent,
    PimDimensionsCardListComponent,
    BrandsManagerComponent,
    BrandsListComponent,
    BrandEditComponent,
    MetaDataSelectInputComponent,
    MetaDataCheckboxInputComponent,
    CustomerOrderFinderComponent,
    OrderFinderCardListComponent,
    OrderFinderListLayoutComponent,
    CustomerOrderAnalyticsComponent,
    OrderAnalyticsListComponent,
    OrderAnalyticsChartTableComponent,
    JetshopManagementPimComponent,
    NewsPageGridViewComponent,
    NewsPageSingleComponent,
    ActuatorWrapperComponent,
    ActuatorInfoComponent,
    ConfigRestartComponent,
    OrderContextComponent,
    ActivityInternalListComponent,
    ActivityInternalTableComponent,
    ActivityInternalViewModalComponent,
    ActivityInternalWrapperComponent,
    ActivityInternalCreateModalComponent,
    UpdateUserModalComponent,
    PimProductShopifyVariantsComponent,
    PimProductShopifyComponent,
    PimProductShopifyAttributesComponent,
    AssortmentManagementComponent,
    AssortmentManagementImportComponent,
    AssortmentManagementExportComponent,
    DiscountCodesComponent,
    DiscountCodesTableComponent,
    DiscountCodesModalComponent,
    ShippingChargesManagerComponent,
    AssignDiscountCodeComponent,
    QuickorderComponent,
    QuickorderProductDetailsComponent,
    QuickorderSummarizeComponent,
    QuickorderListingProductComponent,
    SendCountingTransactionComponent,
    EdiListOrdersComponent,
    EdiOrderCardListComponent,
    EdiOrderDetailComponent,
    ProductsBookmarkCreateModalComponent,
    ProductsBookmarkManagementComponent,
    ProductsBookmarkManagementModalComponent,
    MongoAssortmentManagementModalComponent,
    MongoAssortmentManagementComponent,
    MongoAssortmentCreateModalComponent,
    EdiInvoicesComponent,
    EdiInvoiceCardListComponent,
    EdiInvoiceDetailComponent,
    EdiDispatchAdvicesComponent,
    EdiJointOrderSummaryComponent,
    EdiModalReleaseOrdersComponent,
    EdiModalChangeDeliveryDateComponent,
    EdiModalAddCampaignProductsComponent,
    EdiDispatchCardListComponent,
    EdiDispatchAdviceDetailComponent,
    EdiOrderResponseComponent,
    EdiOrderResponseCardListComponent,
    EdiOrderResponseDetailComponent,
    EdiOrderBulkEditComponent,
    EdiGlnDetailsTableComponent,
    AssignSystemSettingComponent,
    WhiteboardGridComponent,
    WhiteboardActionsComponent,
    WhiteboardOptionsComponent,
    WhiteboardProductListComponent,
    WhiteboardSaveModalComponent,
    WhiteboardProductListViewComponent,
    WhiteboardManagementModalComponent,
    WhiteboardManagementComponent,
    WhiteboardCreateModalComponent,
    WhiteboardOrderProductListComponent,
    DocumentsEditModalComponent,
    WhiteboardPrintComponent,
    ProductListWrapperComponent,
    ProductListV2Component,
    ProductGridViewV2Component,
    ProductListViewV2Component,
    ProductDetailWrapperComponent,
    CloudPimProductFieldsComponent,
    ProductDetailV2Component,
    BuyButtonWrapperComponent,
    BuyBtnMatrixComponent,
    ProductListBannerComponent,
    EdiModalCancelLineComponent,
    EdiModalChangeLineComponent,
    EdiGlnNumberTabsComponent,
    ProductGridCardV2Component,
    ConceptGridCardV2Component,
    BaseGridCardV2Component,
    ConceptDetailV2Component,
    ConceptDetailV2ProductListViewComponent,
    EdiErrorMessagesComponent,
    ConceptDetailV2TopDetailsComponent,
    EdiRowTableComponent,
    GungFlowDetailsSliderComponent,
    DeliveryDatePickerBetaComponent,
    FrontendSettingsComponent,
    TopSoldArticlesComponent,
    TopProductGridComponent,
    TranslationManagementComponent,
    AvailabilityTransactionsComponent,
    ContextTotalPriceComponent,
    BuyBtnInputComponent,
    EdiSendStatusComponent,
    TranslationManagementListComponent,
    TopProductsSummaryComponent,
    GunglowDetailsDropsComponent,
    TopProductsCreatePurchaseOrderModalComponent,
    BarcodeScannerComponent,
    ProductsInCartComponent,
    UserDetailsManagedSupplierComponent,
    EdiDetailErrorDisplayComponent,
    OpenPurchaseOrdersComponent,
    OpenPurchaseOrdersCardListComponent,
    OrderConfirmationContactsModalComponent,
    OpenPurchaseOrdersDetailsComponent,
    MyCompanyComponent,
    PimProductProxyIdComponent,
    BarcodeProductDetailsComponent,
    BarcodeQuickCheckoutComponent,
    CustomerContactAddEditModalComponent,
    CustomerContactsGungListComponent,
    CustomerContactsGungCardListComponent,
    ActivityInternalTodoComponent,
    ActivityInternalTodoTableComponent,
    ScannerComponent,
    BarcodeScannerWrapperComponent,
    PdfDownloadIndicatorComponent,
    UnderServiceNotificationComponent,
    PimDimensionCreateModalComponent,
    CreditBlockedModalComponent,
    AssignUserSettingComponent,
    LoadSavedCartModalComponent,
    PdfToCartSavedMappingsComponent,
    PimProductPricesComponent,
    PriceLevelsComponent,
    DigitalAssetsModalComponent,
    SystemSettingsComponent,
    ProductConceptInlineSearchCardComponent,
    InlineSearchProductConceptNavbarComponent,
    SelectProductImageExportSizeModalComponent,
    OfferListComponent,
    OfferCardListComponent,
    OfferDetailsComponent,
    CustomerConditionsComponent,
    GungMaintenanceComponent,
    DeleteSavedCartModalComponent,
    OfferConfirmationComponent,
    CreateCustomerModalComponent,
    UpdateCoverSheetImagesModalComponent,
    SchedulersMonitorListComponent,
    SchedulersMonitorListViewComponent,
    ReturnWarrantyComponent,
    ReturnWarrantyListComponent,
    ReturnWarrantyTableComponent,
    MetaMultiSelectInputComponent,
    UploadItemsFromExcelComponent,
    ReturnWarrantyCreateViewModalComponent,
    OrderFinderTableListComponent,
    ConceptProductsModalComponent,
    SavedCartProductModalComponent,
    HomeSlideProductListTemplateComponent,
    PimTemplateItemEditorComponent,
    PimTemplateItemEditorModalComponent,
    PimTemplateCreateModalComponent,
    SelectIntervalOrdersDatesModalComponent,
    ChangeLogLevelComponent,
    ConfigurationsEditorComponent,
    SupplierStartPageComponent,
    SupplierWrapperComponent,
    SupplierPoSplitModalComponent,
    SupplierPoListTableViewComponent,
    SupplierPoListComponent,
    SupplierPoHistoryModalComponent,
    SupplierPoDetailsModalComponent,
    SupplierGridViewComponent,
    CheckoutCartListComponent,
    ProductInlineAndCategoriesSearchComponent,
    HtmlTemplateComponent,
    CustomPageHtmlEditorComponent,
    ProductIdsCardShowComponent,
    GungViewer3dComponent,
    GungViewer3dModalComponent,
    GungViewer3dWrapperComponent,
    NavbarSearchViewMoreListComponent,
    EdiModalChangeQuantityComponent,
    SupplierCommentsModalComponent,
    CheckoutCartDiscountMatrixModalComponent,
    CheckoutAddRowModalComponent,
    CustomPageMissingComponent,
    ActivityInternalGungCalendarComponent,
    MapModalComponent,
    GungMapComponent,
    OrderAnalyticsChartTableHorizontalComponent,
    OrderAnalyticsStandaloneComponent,
    ActuatorShopifyComponent,
    ActivitiesCardListComponent,
    ActivitiesEditorModalComponent,
    ActivitiesListComponent,
    MatrixDiscountsComponent,
    MatrixDiscountsCardListComponent,
    MatrixDiscountModalComponent,
    CarouselProductGridViewComponent,
    CustomPageTextInputEditorModalComponent,
    EanReplaceModalComponent,
    ItemsChangesUpdateModalComponent,
    InactiveFeatureComponent,
    ProductionOrdersListComponent,
    StripePaymentStepComponent,
    ProductionOrdersListComponent,
    ProductionOrderWrapperComponent,
    ProductionOrderGungCalendarComponent,
    LoggedInUserListComponent,
    BulkCheckoutWrapperComponent,
    SelectSavedCartModalComponent,
    BulkSummaryComponent,
    BulkPlanComponent,
    BulkCheckoutButtonsComponent,
    EanRepositoryCardListComponent,
    OrderConfirmConfigurationComponent,
    HubspotContactListComponent,
    HubspotContactCardListComponent,
    InactiveUsersNotificationConfigurationComponent,
    EdiPurchasedOrderDetailComponent,
    CompanyTemplatesListComponent,
    CompanyTemplatesListViewComponent,
    CompanyTemplatesAddEditModalComponent,
    SimpleProductListByIdsComponent,
    EdiIncomingOrderResponseDetailComponent,
    EdiIncomingOrderResponseComponent,
    EdiListPurchasedOrdersComponent,
    EdiPurchasedOrderCardListComponent,
    EdiIncomingDespatchAdvicesComponent,
    EdiIncomingDespatchAdviceDetailComponent,
    EdiIncomingDespatchCardListComponent,
    AdyenPaymentStepComponent,
    AdyenPaymentFailedComponent,
    MockrolesWarningComponent,
    OrderAnalyticsProductTableComponent,
    HomeImagesAndProductsTemplateComponent,
    ActuatorEnvironmentComponent,
    ActuatorEnvironmentListComponent,
    GungSettingsComponent,
    GungSettingsBaseViewComponent,
    GungSettingsUsersViewComponent,
    MetaSelectInputHorizontalComponent,
    AdyenPaymentSuccessfulComponent,
    PimVariantsWrapperComponent,
    PimVariantsProductComponent,
    SupplierPoAllListComponent,
    SupplierPoAllListTableViewComponent,
    AdyenPaymentComponent,
    GlobalSearchComponent,
    GlobalSearchProductDetailComponent,
    GlobalSearchCardComponent,
    ImageCarouselMinimalComponent,
    PriceWrapperComponent,
    MultiOrderExcelImportResultComponent,
    CustomerGungAddressCardListComponent,
    CustomerAddGungAddressModalComponent,
    CustomerGungAddressComponent,
    FashionResetPasswordComponent,
    ResetPasswordWrapperComponent,
    MarketingMaterialsTemplateComponent,
    MyCompanyFooterComponent,
    GungAppComponent,
    GungNavbarComponent,
    GungMainMenuComponent,
    GungExpandMenuComponent,
    CustomerListTableComponent,
    DeliveryLocationsComponent,
    AssignDefaultsComponent,
    SummarizeOrderComponent,
    OrderTermsComponent,
    GungNavbarCartComponent,
    HomeHeroBannerAndCategoriesTemplateComponent,
    HomeHeroBannerProductCardsComponent,
    AdyenRedirectResultComponent,
    AdyenOrderFailureComponent,
    AdyenPayInvoiceComponent,
    CustomerCustomTableBodyComponent,
    GungSettingsFortnoxOfferConfirmationComponent,
    GungSettingsFortnoxOrderConfirmationComponent,
    GungSettingsSecurityViewComponent,
    GungSettingsCustomerTemplatesComponent,
    GungSettingsConfigOrderViewComponent,
    GungSettingsConfigOfferViewComponent,
    GungSettingsNavigationViewComponent,
    HomeNavigationConfigComponent,
    EditProductModalComponent,
    HomeNavigationConfigComponent,
    MenuConfigurationMatrixComponent,
    ArticlesFirstSubassortmentComponent
  ],
  imports: [
    GungCommonModule,
    GungListModule,
    GungDynamicPdfModule,
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    MatToolbarModule,
    MatListModule,
    MatExpansionModule,
    MatMenuModule,
    MatExpansionModule,
    MatCardModule,
    MatSidenavModule,
    MatTableModule,
    MatSortModule,
    NgxImageZoomModule,
    AngularEditorModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, -110]
    }),
    StateModule,
    FormsModule,
    CdkStepperModule,
    DragDropModule,
    TranslateModule,
    Angulartics2Module.forRoot(),
    NgChartsModule,
    GridsterModule,
    CarouselModule
  ],
  providers: [
    CanDeactivateGuard,
    FeatureActivatedGuardWrapper,
    {
      provide: LOCALE_ID,
      useValue: 'sv'
    },
    {
      provide: GOOGLE_ANALYTICS_ID,
      useValue: ''
    },
    {
      provide: GOOGLE_TAG_MANAGER_ID,
      useValue: 'GTM-M8XLWRH'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptorService,
      multi: true
    },
    {
      provide: NgbDatepickerI18n,
      useClass: MultilangDatepickerService
    },
    DatePipe,
    ProductSelectionService,
    // CustomPageResolverService
    GungCurrencyPipe,
    {
      provide: CommonDocumentTemplatesService,
      useClass: DocumentTemplatesService
    },
    {
      provide: PdfProductService,
      useClass: ProductService
    },
    {
      provide: PdfFlowService,
      useClass: GungFlowService
    },
    {
      provide: PdfAuthService,
      useClass: AuthService
    },
    {
      provide: PdfProductExportService,
      useClass: ProductExportService
    },
    {
      provide: ExportDragConfigService,
      useClass: DynamicPdfProductListConfigService
    },
    {
      provide: PdfSelectedCustomerService,
      useClass: SelectedCustomerService
    },
    {
      provide: dynamicPdfDownloadIndicatorService,
      useExisting: PdfDownloadIndicatorService
    },
    {
      provide: GungLanguageConfigService,
      useExisting: LanguageConfigService
    },
    {
      provide: FilterListDateUtilService,
      useClass: GungStandardFilterListDateUtilService
    },
    {
      provide: ExportPdfOrderProductListConfigService,
      useClass: DynamicPdfOrderProductListConfigService
    },
    {
      provide: CommonRoutingUtilService,
      useClass: RoutingUtilService
    },
    provideHttpClient(withInterceptorsFromDi())
  ],
  exports: [
    ConfirmationEmailsComponent,
    ProductListComponent,
    ProductExportListComponent,
    LoginComponent,
    ForgotPasswordComponent,
    NavbarComponent,
    BuyBtnComponent,
    CustomerDetailsComponent,
    ProductCardComponent,
    ProductExportCardComponent,
    ProductExportTableComponent,
    OrderCardListComponent,
    InvoiceCardListComponent,
    CustomerContactListComponent,
    CustomerUserListComponent,
    CartItemComponent,
    ProductDetailComponent,
    LangSelectComponent,
    AppWrapperComponent,
    GungCurrencyPipe,
    MetaSelectInputComponent,
    MetaDataSelectInputComponent,
    MetaDataCheckboxInputComponent,
    CartComponent,
    DeliveryDatePickerComponent,
    CartSummaryComponent,
    ThankYouComponent,
    CustomStepperComponent,
    OrderListComponent,
    GungDateOutputComponent,
    GungMetaOutputComponent,
    CustomerSelectDropdownComponent,
    CartSalesStepComponent,
    ButtonsComponent,
    OrderDetailsComponent,
    NavbarCartComponent,
    DiscountInputComponent,
    MyCustomPagesComponent,
    ProductListByIdsComponent,
    OpenOrdersComponent,
    OpenOrderCardListComponent,
    QuotesComponent,
    QuotesCardListComponent,
    InvoiceListComponent,
    OpenOrderTableComponent,
    QuotesTableComponent,
    InvoiceTableComponent,
    ProductPaginationListComponent,
    CustomerListComponent,
    ProductFastSearchComponent,
    ProductFastsearchDetailComponent,
    AvailabilityComponent,
    ProductSelectDropdownComponent,
    LangSelectComponent,
    FlowSelectComponent,
    AssignFlowSettingComponent,
    OrderConfirmationComponent,
    SavedCartListComponent,
    QuantityValidationStepComponent,
    CustomPageEditorComponent,
    HomeDefaultTemplateComponent,
    CustomPageDisplayComponent,
    CustomPageEditorModalComponent,
    PriceComponent,
    FavouriteProductIconComponent,
    ProductGridViewComponent,
    ProductDetailMatrixModalComponent,
    ProductMatrixComponent,
    ProductMatrixWrapperInputElementComponent,
    OrderChangeCustomerConfirmationModalComponent,
    BackButtonComponent,
    FooterComponent,
    RequestAccountComponent,
    FashionLoginComponent,
    ForgotPasswordComponent,
    ProductMatrixInputElementComponent,
    ProductMatrixElementSimplifiedAvailabilityComponent,
    ProductMatrixSummaryElementComponent,
    BreadcrumbComponent,
    ImageCarouselComponent,
    ThankYouComponent,
    PimProductComponent,
    PimProductAttributesComponent,
    PimProductBasicInfoComponent,
    PimProductDocumentsComponent,
    PimProductImagesComponent,
    PimProductRelatedProductsComponent,
    PimProductVariantsComponent,
    PdfToCartComponent,
    UserDetailsComponent,
    UserDetailsCustomersComponent,
    UserDetailsMultistocksComponent,
    UserDetailsRolesComponent,
    UserDetailsGroupsComponent,
    UserDetailsUserComponent,
    UserDetailsSubassortmentComponent,
    UserDetailsOrderParamsComponent,
    ProductInlineSearchComponent,
    OrderSubmitErrorComponent,
    ReturnWarrantyListComponent,
    SafeHtmlPipe,
    GungFlowDetailsBasicSettingsComponent,
    GungFlowDetailsProductSettingsComponent,
    GungFlowDetailsOrderSettingsComponent,
    GungFlowDetailsHtmlComponent,
    GungFlowDetailsSliderComponent,
    GunglowDetailsDropsComponent,
    FavouriteProductListComponent,
    GungFlowDetailsComponent,
    PimDimensionsCardListComponent,
    PimDimensionsModalComponent,
    PimUploadModalComponent,
    CustomerOrderFinderComponent,
    CustomerOrderAnalyticsComponent,
    ImportLinesheetComponent,
    MultiOrderExcelImportResultComponent,
    JetshopManagementPimComponent,
    SavedQuoteTableComponent,
    ActivityInternalListComponent,
    ExportCoverSheetsComponent,
    NewCoverSheetModalComponent,
    PimProductShopifyVariantsComponent,
    PimProductShopifyComponent,
    PimProductShopifyAttributesComponent,
    SavedCartTableComponent,
    AssignDiscountCodeComponent,
    DocumentsUploadModalComponent,
    QuickorderProductDetailsComponent,
    DigitalAssetsFolderGridViewComponent,
    QuickorderComponent,
    QuickorderSummarizeComponent,
    QuickorderListingProductComponent,
    QuickorderProductDetailsComponent,
    ProductDetailV2Component,
    CloudPimProductFieldsComponent,
    BuyButtonWrapperComponent,
    BuyBtnMatrixComponent,
    ProductListViewV2Component,
    DeliveryDatePickerBetaComponent,
    NewsPageSingleComponent,
    AvailabilityTransactionsComponent,
    ContextTotalPriceComponent,
    BuyBtnInputComponent,
    ShippingChargesManagerComponent,
    OrderAnalyticsChartTableComponent,
    PimProductProxyIdComponent,
    CustomerContactsGungListComponent,
    ProductsInCartComponent,
    BarcodeQuickCheckoutComponent,
    BarcodeProductDetailsComponent,
    PdfDownloadIndicatorComponent,
    BarcodeScannerWrapperComponent,
    UnderServiceNotificationComponent,
    MongoAssortmentCreateModalComponent,
    MongoAssortmentManagementModalComponent,
    GungFlowsWrapperComponent,
    CreateGungFlowModalComponent,
    PimProductPricesComponent,
    GungFlowsTableComponent,
    LoginModalComponent,
    CustomerConditionsComponent,
    ProductListBannerComponent,
    CreateCustomerModalComponent,
    MetaMultiSelectInputComponent,
    UserDetailsManagedSupplierComponent,
    ReturnWarrantyTableComponent,
    OrderFinderTableListComponent,
    ReturnWarrantyCreateViewModalComponent,
    HomeSlideProductListTemplateComponent,
    OfferListComponent,
    ConceptProductsModalComponent,
    ProductInlineAndCategoriesSearchComponent,
    CustomPageDirectiveDirective,
    DigitalAssetsManagementItemComponent,
    ProductIdsCardShowComponent,
    DiscountCodesModalComponent,
    PriceLevelsComponent,
    ActivityInternalGungCalendarComponent,
    OrderAnalyticsChartTableHorizontalComponent,
    OrderAnalyticsStandaloneComponent,
    GungMapComponent,
    ActivitiesListComponent,
    HtmlTemplateComponent,
    SupplierCommentsModalComponent,
    CarouselProductGridViewComponent,
    HubspotContactListComponent,
    SimpleProductListByIdsComponent,
    MyCompanyComponent,
    MockrolesWarningComponent,
    MetaSelectInputHorizontalComponent,
    ProductListWrapperComponent,
    EdiGlnNumberTabsComponent,
    EdiDetailErrorDisplayComponent,
    EdiRowTableComponent,
    EdiErrorMessagesComponent,
    EdiSendStatusComponent,
    AdyenPaymentComponent,
    GlobalSearchComponent,
    ImageCarouselMinimalComponent,
    ImportUsersComponent,
    PriceWrapperComponent,
    CustomerGungAddressComponent,
    CustomerAddGungAddressModalComponent,
    CustomerGungAddressCardListComponent,
    DiscountCodesTableComponent,
    GungAppComponent,
    GungNavbarComponent,
    GungMainMenuComponent,
    GungExpandMenuComponent,
    GungNavbarCartComponent,
    DeliveryLocationsComponent,
    StripePaymentStepComponent
  ]
})
export class GungStandardModule {
  constructor(
    router: Router,
    translate: TranslateService,
    authService: AuthService,
    newsActionConfigService: NewsActionConfigService,
    loginCheckService: LoginCheckService,
    backendInterceptor: BackendInterceptor,
    @Optional()
    @Inject('environment')
    protected environment,
    protected gungCompanyService: GungCompanyService,
    protected customerService: SelectedCustomerService,
    protected gungTitleService: GungTitleService
  ) {
    gungTitleService.initGungTitleService();
    loginCheckService.initPoll();

    router.routeReuseStrategy.shouldReuseRoute = (future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) => {
      if (future.routeConfig && future.routeConfig.path && curr.routeConfig && curr.routeConfig.path) {
        const noReuseRoutes = [];
        noReuseRoutes.push('categories/:assortmentId');
        noReuseRoutes.push('product/:productId');
        noReuseRoutes.push('products');
        noReuseRoutes.push('articles');

        if (noReuseRoutes.includes(future.routeConfig.path) && noReuseRoutes.includes(curr.routeConfig.path)) {
          return false;
        }
      }

      return future.routeConfig === curr.routeConfig;
    };

    // defaultLang is the fallback language, not the "default selected" language. I.e. if we have a translate tag without
    // value for a language, it will fall back to the defaultLang instead of showing the tag name.
    // Looking at the git log it has been added and removed continuously for a long time. Please don't remove.
    translate.setDefaultLang('en');

    registerLocaleData(localeSv);
    registerLocaleData(localeEn);

    // open If Enabled User News In Modal
    // get the first event that the user state is marked as authenticated and fetch news

    authService
      .getIsAuthenticated()
      .pipe(
        filter(isAuthenticated => isAuthenticated),
        first()
      )
      .subscribe(() => {
        customerService
          .getSelectedCustomer()
          .pipe(
            filter(cust => !!cust),
            first()
          )
          .subscribe(() => newsActionConfigService.openUserNewsInModal());
      });

    this.setupCustomization();
  }

  private setupCustomization() {
    this.gungCompanyService
      .getColors()
      .pipe(first())
      .subscribe(colors => {
        if (colors?.primary || colors?.secondary) {
          setColors(colors?.primary, colors?.secondary, colors?.hover, colors?.contrasting);
        }
      });

    this.gungCompanyService
      .getExtraCustomization()
      .pipe(first())
      .subscribe(customization => {
        this.loadScript(customization?.indexHead || null);
      });

    this.gungCompanyService
      .getFaviconUrlObservable()
      .pipe(first())
      .subscribe(favicon => {
        var element = document.getElementById('favicon') as HTMLAnchorElement;
        if (!element) {
          element = document.querySelectorAll('[rel="shortcut icon"]')?.[0] as HTMLAnchorElement;
        }
        if (favicon && element) {
          element.href = favicon;
        }
      });
  }

  loadScript(scriptCode: string) {
    if (!scriptCode) {
      return;
    }

    document.head.insertAdjacentHTML('beforeend', scriptCode);
  }
}
