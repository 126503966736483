<div class="table-responsive-md">
  <table class="table">
    <thead class="thead-sticky">
      <tr>
        <th translate>ID</th>
        <th translate>NAME</th>
        <th *ngIf="!isAnonymous" translate>AVAILABILITY</th>
        <th *ngIf="!isAnonymous" translate>PRICE</th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of mappedData">
        <td>
          <div class="d-flex">
            <div class="d-flex flex-column">
              <a
                [routerLink]="[
                  item.product.extra.assortmentParentPath
                    ? '/articles/' + item.product.extra.assortmentParentPath
                    : '/product/',
                  item.id
                ]"
                class="text-dark"
              >
                <span>
                  {{ item.id }}
                </span>
                <br />
                <b
                  *ngIf="
                    selectedCustomer &&
                    item.product.extra._customerCrossReferences &&
                    item.product.extra._customerCrossReferences[selectedCustomer.id]
                  "
                  class="small"
                >
                  {{ item.product.extra._customerCrossReferences[selectedCustomer.id].referenceId }}
                </b>
              </a>
            </div>
            <a class="ml-2" (click)="openCrossReferenceEditor(item)">
              <i class="fas fa-edit"></i>
            </a>
          </div>
        </td>
        <td>
          <div class="d-flex flex-column">
            <div>
              <a
                [routerLink]="[
                  item.product.extra.assortmentParentPath
                    ? '/articles/' + item.product.extra.assortmentParentPath
                    : '/product/',
                  item.id
                ]"
                class="text-dark mb-1"
              >
                {{ item.product | productName }}
              </a>
            </div>
            <span>{{ item.product.extra.ar.artbeskrspec }}</span>
          </div>
        </td>
        <td *ngIf="!isAnonymous">
          <otto-olsen-availability
            *ngIf="item.availability"
            [availability]="item.availability"
            [unit]="item.product.extra.ar?.enhetskod"
            [decimals]="item.product.extra.ar.antdec"
            [productionDays]="item.product?.extra.ar?.anskaffningssatt && (item.product?.extra.ar?.anskaffningssatt * 1) === 12 && item.product?.extra.ar?.artgenomloppstid"
          ></otto-olsen-availability>
        </td>
        <td>
          <!-- <span
            *ngIf="
              item.price &&
              !(
                item.product.extra.pim &&
                item.product.extra.pim.pricerequest &&
                item.product.extra.pim.pricerequest !== 'false'
              ) &&
              !(item.product.extra.ar.itemtypecd1 === 0 || item.product.extra.ar.itemtypecd1 === 7) &&
              (item?.product?.extra?.pim?.activeInB2B || isSales)
            "
          >
            <lib-price [price]="item.price" type="GROSS"></lib-price>
            <span *ngIf="item.price.customerDiscountPercent !== 0">(-{{ item.price.customerDiscountPercent }}%)</span>
            <span
              *ngIf="
                item.price.customerDiscountPercent === 0 &&
                Number(item.product.extra.ar.artlistpris) !== Number(item.price.backendPriceLevel.price)
              "
              >({{ 'NET_PRICE' | translate }})</span
            >
          </span> -->
          <div *ngIf="isAnonymous || (!(
            item.product.extra.pim &&
            item.product.extra.pim.pricerequest &&
            item.product.extra.pim.pricerequest !== 'false'
          ) && !(item.product.extra.ar.itemtypecd1 === 0 || item.product.extra.ar.itemtypecd1 === 7))">
            <otto-olsen-price-list-display [price]="item.price" [product]="item.product"></otto-olsen-price-list-display>
          </div>
        </td>
        <td>
          <div
            class="buy-btn-width-table"
            *ngIf="
              isAnonymous ||
                (!(
                  item.product.extra.pim &&
                  item.product.extra.pim.pricerequest &&
                  item.product.extra.pim.pricerequest !== 'false'
                ) &&
                  !(item.product.extra.ar.itemtypecd1 === 0 || item.product.extra.ar.itemtypecd1 === 7));
              else priceRequest
            "
          >
            <otto-olsen-buy-btn
              name="buy-btn-package-size"
              *ngIf="item.replacementId == null"
              [id]="item.id"
              [minimumOrderQuantity]="item.firstStepAmount || item.packageSize"
              [stepAmount]="item.packageSize"
              [unit]="item.product.extra?.ar?.enhetskod"
              [decimals]="item.product.extra.ar.antdec"
              [activeInB2B]="item?.product?.extra?.pim?.activeInB2B"
              [product]="item?.product"
              [availability]="item.availability"
            >
            </otto-olsen-buy-btn>
          </div>
          <ng-template #priceRequest>
            <a
              href="mailto:{{ requestPriceEmail }}?subject=Forespørsel {{ item.id }}"
              target="_blank"
              class="request-price-email"
            >
              <button type="button" class="btn btn-block btn-sm btn-primary" translate>ASK_PRICE</button>
            </a>
          </ng-template>
        </td>
        <td>
          <otto-olsen-favourite-product-icon-only
            class="ml-1"
            [id]="item.product.id"
            (click)="$event.preventDefault(); $event.stopPropagation()"
          >
          </otto-olsen-favourite-product-icon-only>
        </td>
      </tr>
    </tbody>
  </table>
</div>
