<!--MODAL HEADER-->
<div class="modal-header">
  <h4 class="modal-title" translate>
    <span *ngIf="create" translate>CREATE_NEW</span>
    <span translate>RETURN_WARRANTY</span> {{ data?.id }}
  </h4>
  <div>
    <div *ngIf="!create && isAdmin" ngbDropdown placement="bottom-right" class="d-inline-block dropup mt-3 ml-auto">
      <button class="btn btn-outline-primary export-area-buttons" id="dropdownBasic1" ngbDropdownToggle translate>
        ACTIONS
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-container">
        <button
          class="export-area-buttons"
          ngbDropdownItem
          *ngFor="let button of actions | async"
          (click)="button.performAction(null)"
          translate
        >
          {{ button.label }}
        </button>
      </div>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

</div>
<!--MODAL BODY-->
<div class="modal-body" *ngIf="isLoaded; else loading">
  <form [formGroup]="submitForm">
    <fieldset [disabled]="!editHeader && !create" class="bg-light p-1 m-2">
      <div class="row">
        <div class="col">
          <lib-gung-input-dropdown-filter
            gungTranslate="CUSTOMER"
            formControlName="customerId"
            [renderStatic]="isUser || (!editHeader && !create)"
            [formatter]="formatterCustomer"
            [formatterView]="formatterViewCustomer"
            [list]="customerList">
          </lib-gung-input-dropdown-filter>
        </div>
        <div class="col-4" *ngIf="!create">
          <lib-gung-input-dropdown-filter
            formControlName="assignTo"
            onSelected="$event ? this.submitForm.get('assignTo').setValue($event.id + ' ' + $event.name) : undefined"
            gungTranslate="ASSIGN_TO"
            [renderStatic]="!editHeader && !create"
            [list]="users">
          </lib-gung-input-dropdown-filter>
        </div>
        <div class="col-4" *ngIf="!create">
          <lib-gung-input-dropdown-filter
            formControlName="approvedBy"
            onSelected="$event ? this.submitForm.get('approvedBy').setValue($event.id + ' ' + $event.name) : undefined"
            gungTranslate="APPROVED_BY"
            [renderStatic]="!editHeader && !create"
            [list]="users">
          </lib-gung-input-dropdown-filter>
        </div>
        <div class="col-4">
            <label for="" translate>STATUS</label>
            <lib-meta-data-select-input
              table="CLAIMSTATUS"
              suppressLabel="true"
              metaId="name"
              metaDisplay="name"
              [showId]="false"
              class="gung-select-input-sm-NOT-USE"
              gungTranslate="STATUS"
              formControlName="status"
              [renderStatic]="isUser || (!editHeader && !create)"
            ></lib-meta-data-select-input>
          </div>
          <div class="col-4">
            <lib-gung-date-picker
              [minInitialDate]="today"
              [currentselectedDate]="submitForm.get('date').value"
              (selectedDate)="submitForm.get('date').setValue($event)"
              label="DATE_CREATED"
              [extraInputClass]="'form-control-sm'"
              extraBtnClass="btn-sm"
              [renderStatic]="isUser">
            </lib-gung-date-picker>
          </div>
      </div>
    </fieldset>
    <div class="row">
      <div class="col-12 mx-2">
        <lib-gung-accordion>
          <lib-gung-accordion-item>
            <ng-template libGungAccordionHeader>{{'SHOW_MORE' | translate}} {{'COMMENT' | translate}}</ng-template>
            <ng-template libGungAccordionBody>
              <ng-container *ngTemplateOutlet="comments; context: { $implicit: data?.comments}"></ng-container>
            </ng-template>
          </lib-gung-accordion-item>        
        </lib-gung-accordion>
      </div>
    </div>

    <div *ngIf="!create && data.comments?.length > 0">
      <ng-container *ngTemplateOutlet="comments; context: { $implicit: [data.comments[data.comments.length-1]]}"></ng-container>
    </div>
    <ng-template #comments let-comments let-index="index">
      <div  class="comments bg-light m-2 p-1">
        <div
          class="row"
          *ngFor="let comment of comments"
          [class.justify-content-end]="currentUser.username === comment.user"
        >
          <div
            class="col-10 rounded m-2"
            [ngClass]="{ 'bg-gainsboro': currentUser.username !== comment.user, 'bg-light-green': currentUser.username === comment.user }"
          >
            <div class="row">
              <div class="col-6 text-left">
                {{ comment.user }}
              </div>
              <div class="col-6 text-right">
                {{ comment.date | date : dateUtilService.dateFormat }}
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                {{ comment.comment }}
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-right">
                <ng-container *ngFor="let attachment of comment.attachments">
                  <div class="d-block">
                    <a
                      *ngIf="attachment?.includes('/'); else oldDocument"
                      [title]="attachment.split('/').pop()"
                      [href]="attachment | gungDownloadUrl"
                      class="mx-2 text-dark border-0"
                    >
                      {{ attachment.split('/').pop() }}
                      <i class="fa-solid fa-cloud-arrow-down fa-1x"></i>
                    </a>
                    <ng-template #oldDocument>
                      <a (click)="downloadFile(attachment)" class="mx-2 text-dark border-0">
                        <i class="fas fa-download fa-1x"></i>
                      </a>
                    </ng-template>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="bg-light p-1 m-2">
      <div class="row">
        <div class="col-8">
          <lib-gung-text-area-input formControlName="comment" gungTranslate="COMMENT"></lib-gung-text-area-input>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="w-100" for="file-upload" translate>ATTACH_FILES</label>
            <lib-gung-input-file
              accept="image/*"
              [multiple]="multipleFiles"
              (onFileSelect)="onFileSelect($event)"
            ></lib-gung-input-file>
          </div>
        </div>
      </div>
    </div>
    <!-- END header -->
    <div class="row">
      <div class="col-12">
        <h2 translate>PRODUCTS</h2>
        <div class="table-responsive gung-responsive return-row-table">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col" translate>PRODUCT_ID</th>
                <th scope="col" width="100px" translate>QUANTITY</th>
                <th scope="col" translate>DATE</th>
                <th scope="col" translate>REASON</th>
                <th scope="col" translate><span translate>ORDER_ID</span>/<span translate>NOTE</span></th>
                <th scope="col" translate>ATTACH_FILES</th>
                <th style="width: 1%"><!-- ACTIONS --></th>
              </tr>
            </thead>
            <tbody formArrayName="rows">
              <tr *ngFor="let row of rows.controls; let i = index" [formGroupName]="i">
                <th [attr.data-label]="'#'" scope="row">{{ i }}</th>
                <td [attr.data-label]="'PRODUCT_ID' | translate">
                  <lib-gung-text-input-typeahead
                    formControlName="id"
                    [suppressLabel]="true"
                    [renderStatic]="!create && !editRow[i]"
                    (selectItem)="selectItem($event, i)"
                    [search]="itemIdSearchTypeahead"
                    [resultFormatter]="resultFormatterItemId"
                  >
                  </lib-gung-text-input-typeahead>
                  <lib-forms-message-error [controller]="row.get('id')"></lib-forms-message-error>
                </td>
                <td [attr.data-label]="'QUANTITY' | translate">
                  <lib-gung-number-input-horizontal
                    formControlName="quantity"
                    suppressLabel="true"
                    [renderStatic]="!create && !editRow[i]"
                  ></lib-gung-number-input-horizontal>
                </td>
                <td [attr.data-label]="'DATE' | translate">
                  <lib-gung-date-picker
                    [currentselectedDate]="row.get('date').value"
                    (selectedDate)="row.get('date').setValue($event)"
                    suppressLabel="true"
                    extraInputClass="form-control-sm-NOT-USED"
                    extraBtnClass="btn-sm-NOT-USED"
                    [renderStatic]="!create && !editRow[i]"
                    [disabled]="!create && !editRow[i]">
                  </lib-gung-date-picker>
                </td>
                <td [attr.data-label]="'REASON' | translate">
                  <ng-container formArrayName="reasonCode" *ngFor="let reason of rowsIdx(i)?.get('reasonCode')?.controls; let idx = index">
                    <ng-container formGroupName={{idx}}>
                      <lib-meta-data-select-input
                        table="gungReturnReasons"
                        metaId="code"
                        metaDisplay="description"
                        [showId]="false"
                        class="gung-select-input-sm-NOT-USE"
                        suppressLabel="true"
                        [formControl]="reason"
                        [renderStatic]="!create && !editRow[i]"
                      ></lib-meta-data-select-input>
                    </ng-container>
                  </ng-container>
                  <!-- <button *ngIf="create || editRow[i]" type="button" class="btn btn-primary" (click)="addReasonCode(i)">
                    <i class="fa-solid fa-square-plus"></i>
                  </button> -->
                </td>
                <td [attr.data-label]="'ORDER_ID' | translate">
                  <lib-gung-text-area-input formControlName="note" suppressLabel="true" [renderStatic]="!create && !editRow[i]"></lib-gung-text-area-input>
                </td>
                <td [attr.data-label]="'ATTACH_FILES' | translate">
                  <div formArrayName="attachments">
                    <div *ngFor="let attachment of row.getRawValue().attachments; let indexAttachment = index">
                      <span *ngIf="attachment && !attachment.name" class="d-block">
                          <a
                            *ngIf="attachment?.includes('/'); else oldDocument"
                            [title]="attachment.split('/').pop()"
                            [href]="attachment | gungDownloadUrl"
                            class="mx-2 text-dark border-0"
                          >
                            {{ attachment.split('/').pop() }}
                            <i class="fa-solid fa-cloud-arrow-down fa-1x"></i>
                          </a>
                          <button *ngIf="!isUser || create" (click)="removeAttachement(i, indexAttachment)" type="button" class="btn btn-sm btn-danger">
                            <i class="fa-solid fa-trash-can"></i>
                          </button>
                          <ng-template #oldDocument>
                            <a (click)="downloadFile(attachment)" class="mx-2 text-dark border-0">
                              <i class="fas fa-download fa-1x"></i>
                            </a>
                          </ng-template>
                      </span>
                    </div>
                  </div>

                  <lib-gung-input-file
                    accept="image/*"
                    [multiple]="true"
                    (onFileSelect)="onFileSelect($event, i)"
                  ></lib-gung-input-file>
                  <lib-forms-message-error [controller]="row.get('attachments')"></lib-forms-message-error>
                </td>
                <td [attr.data-label]="'ACTIONS' | translate">
                  <div class="d-flex">
                    <button *ngIf="!isUser" type="button" class="btn btn-primary" (click)="editRow[i] = !editRow[i]">
                      <i class="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button *ngIf="!isUser || create" type="button" class="btn btn-danger" (click)="deleteRow(i)">
                      <i class="fa-solid fa-trash-can"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <lib-forms-message-error [controller]="submitForm.get('rows')"></lib-forms-message-error>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button *ngIf="!isUser || create" type="button" class="btn btn-primary" (click)="addRow()" translate>ADD_ROW</button>
      </div>
    </div>
  </form>
</div>

<!--MODAL FOOTER-->
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('Cance click')" translate>CANCEL</button>
  <button type="button" class="btn btn-primary" [disabled]="disabled ? true : null" (click)="onSave()" translate>
    SAVE
  </button>
</div>

<ng-template #loading> LOADING... </ng-template>
